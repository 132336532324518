.success-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f9f9f9;
  
    .success-container {
      text-align: center;
      background: #fff;
      padding: 2rem;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      max-width: 400px;
      width: 100%;
  
      .icon-container {
        background-color: #4caf50;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 1rem;
  
        .check-icon {
          color: white;
          font-size: 2.5rem;
        }
      }
  
      h1 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        color: #333;
      }
  
      p {
        font-size: 1rem;
        color: #666;
        margin-bottom: 1.5rem;
      }
  
      .button-group {
        display: flex;
        gap: 3rem;
  
        .btn {
          padding: 0.75rem 1.5rem;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 1rem;
          transition: background-color 0.3s;
  
          &.btn-primary {
            background-color: #4caf50;
            color: #fff;
  
            &:hover {
              background-color: #45a045;
            }
          }
  
          &.btn-secondary {
            background-color: #f0f0f0;
            color: #333;
  
            &:hover {
              background-color: #e0e0e0;
            }
          }
        }
      }
    }
  }
  


  // cancelled css 

  .cancelled-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
    color: #721c24;
  
    .cancelled-container {
      text-align: center;
      background: white;
      padding: 2rem;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
      .icon-container {
        font-size: 3rem;
        color: #f44336;
        margin-bottom: 1rem;
      }
  
      h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
  
      p {
        font-size: 1.2rem;
        margin-bottom: 2rem;
      }
  
      .button-group {
        display: flex;
        justify-content: center;
        gap: 1rem;
  
        .btn {
          padding: 0.8rem 1.5rem;
          font-size: 1rem;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s;
  
          &.btn-primary {
            background-color: #007bff;
            color: white;
  
            &:hover {
              background-color: #0056b3;
            }
          }
  
          &.btn-secondary {
            background-color: #6c757d;
            color: white;
  
            &:hover {
              background-color: #5a6268;
            }
          }
        }
      }
    }
  }
  