@import '../../mixin.scss';
 

.chatBox {position: fixed; right: 5px; bottom: 5px; z-index: 10001;
    &.active {
        .chatTop {display: block;}
    }
}
.chatTop {position: absolute; right: 0; bottom: 75px; width: 550px; background: #fff; box-shadow: 0 0 10px rgba(0,0,0,.1); display: none;}
.chatLeft {width: 190px; background: #eaedf4;
    .chatSearch{border-bottom: 1px solid #cccc; height: 44px; position: relative;
        input {width: 100%; height: 43px; padding: 0 35px 0 15px; border: none; background: none;}
        .closeLink {position: absolute; right: 10px; top: 13px; font-style: normal; cursor: pointer;}
    }
    .hd {border-bottom: 1px solid #cccc; height: 44px; padding: 0 15px; line-height: 44px; color: #000; font-weight: 700; font-size: 18px;}
    .chatUserList {height: 445px; overflow: auto;
        li {padding: 8px 12px; color: #000; font-weight: 600; cursor: pointer; display: flex; align-items: center;
            &:hover {background: #fff;}
            i {display: block; width: 24px; min-width: 24px; height: 24px; background: #000; color: #fff; border-radius: 100%; margin-right: 8px; font-style: normal; text-align: center; line-height: 24px;}
        }
    }
}
.chatRight {width: calc(100% - 190px);
    .hd {border-bottom: 1px solid #cccc; height: 44px; padding: 0 15px; line-height: 44px; color: #000; font-weight: 700; font-size: 18px;}    
}
.messageList {height: 400px; overflow: auto; padding: 15px;
    ul {
        li {margin-bottom: 10px; display: flex;
            .inner {background: #eee; padding: 10px; font-size: 14px; width: 70%; position: relative;
                .moreLink {position: absolute; right: -25px; bottom: 0;
                    a {color: #999;}
                }
            }
            &.left {
                .inner {background: #e8e8e8; border-radius: 10px 10px 10px 0;}
            }
            &.right {justify-content: flex-end; 
                .inner {background: #d9dffd; border-radius: 10px 10px 0 10px;}
            }
            .top {margin-bottom: 5px;
                .name {color: #000; font-weight: 600;}
            }
            .userIcon {background: #10d9d9; width: 32px; height: 32px; min-width: 32px; border-radius: 100%; margin-right: 5px; color: #fff; font-size: 17px; font-weight: 600; text-align: center; line-height: 32px;}
        }
    }
}

.actionBtns {
    button {margin-left: 5px; background: none; line-height: normal;}
}
.chatMessage {border-top: 1px solid #ccc; position: relative; padding-right: 80px;
    .input {width: 100%; height: 44px; border: none; background: none; padding: 0 15px; font-size: 16px; font-weight: 500;}
    .sendBtn {position: absolute; right: 15px; top: 50%; width: 65px; background: #0095fc; height: 28px; color: #fff; font-weight: 600; border-radius: 30px; margin-top: -14px; text-transform: uppercase;}
}