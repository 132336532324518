// new admin dashb oard design css

@import "../../../mixin.scss";

// new admin dashb oard design css

.dbAdminRight.newDbRight {
  background: #01458b;

  .dbMid.dbInner {
    border-radius: 18px 0px 0px 18px;
    background: #ededed;
    padding: 47px 30px;
    min-height: 100vh;
    @include respond-to(media-xs) {
      padding: 30px 20px;
      min-height: 1px;
      border-radius: 0;
    }
  }

  .dbMainHd {
    margin-bottom: 28px;
    position: relative;
    padding-bottom: 40px;
    @include respond-to(media-sm) {
      padding-bottom: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0px;
      border-bottom: 1px solid rgba(4, 35, 54, 0.08);
      bottom: 0;
      left: 0;
    }
  }

  .nwWhtContWrp {
    background-color: #fff;
    padding: 24px 28px;
    border-radius: 8px;
  }

  .addCatForm {
    .form_grpWrp {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 24px;

      label {
        font-size: 15px;
        font-weight: 600;
        line-height: 32px;
        display: flex;
        align-items: center;
        letter-spacing: 0em;
        color: #000;
        width: 21%;
        display: inline-block;
        margin: 0;
        position: relative;
        @include respond-to(media-md) {
          width: 100%;
          margin-bottom: 5px;
        }

        .req {
          color: #db4b4b;
        }
      }

      .form_grp {
        width: 76%;
        @include respond-to(media-md) {
          width: 100%;
        }

        .form_ctrl {
          width: 100%;
          font-size: 16px;
          padding: 16px 24px;
          height: 50px;
          font-weight: 600;
          line-height: 1.2;
          color: #9b9b9b;
          border-radius: 8px;
          background: #ffffff;
          border: 1px solid #d7d7d7;
        }

        .cusSel {
          background: url(../../../assets/images/dropdown_arr.svg) no-repeat
            center;
          background-size: 14px;
          background-position: 97% center;
        }
      }
    }
  }

  .nambaBtn {
    font-size: 18px;
    font-weight: 600;
    line-height: 42px;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
    padding: 10px;
    display: inline-block;
    border-radius: 4px;
    background: #01458b;
    border: 1px solid transparent;
    transition: all 0.5s ease-in;

    &:hover {
      color: #01458b;
      background: #fff;
      border-color: #01458b;
    }
  }

  .nambaBtn.submit-button {
    width: 154px;
    height: 52px;
    line-height: 32px;
    padding: 0;
  }

  .form_grpWrp.btnWrp {
    justify-content: flex-start !important;
    padding-left: 24%;
    @include respond-to(media-md) {
      padding-left: 0;
    }
  }

  .custom-file-label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 100% !important;
  }

  .custom-file-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .custom-file-button {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 20px;
    height: 250px;
    color: white;
    cursor: pointer;
    border-radius: 8px;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px dashed #d7d7d7;

    img {
      width: 64px;
      text-align: center;
      margin: 0 auto;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 42px;
      text-align: center;
      color: #575864;

      span {
        color: #2377fc;
        display: inline-block;
      }
    }
  }

  .file-name {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
  }

  .catFilterWrp {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 25px;

    .catFilterCont {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .filWrp {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-wrap: wrap;
        @include respond-to(media-lg) {
          width: 100%;
          margin-bottom: 16px;
        }
      }
      label,
      .ent {
        font-size: 16px;
        font-weight: 600;
        line-height: 32px;
        display: inline-block;
        color: #000000;
      }

      label {
        margin-right: 21px;
      }

      .ent {
        color: #8c8c8c;
        margin: 0 43px 0 12px;
      }

      .form_ctrl {
        font-size: 16px;
        padding: 14px 20px;
        font-weight: 600;
        line-height: 1.2;
        color: #9b9b9b;
        height: 52px;
        border-radius: 8px;
        background: #ffffff;
        border: 1px solid #fff;
      }

      .ctrlDrop {
        // width: 84px;
        background: #fff url(../../../assets/images/drop_icon.svg) no-repeat
          center;
        background-size: 8px;
        background-position: 80% center;
      }
      .srchGrp {
        @include respond-to(media-sm) {
          width: 100%;
          margin: 16px 0;
        }

        .ctrlSrch {
          width: 480px;
          background: #fff url(../../../assets/images/search.svg) no-repeat
            center;
          background-size: 24px;
          background-position: 97% center;
          @include respond-to(media-lg) {
            width: 100%;
          }
        }
      }
    }
  }
  .tblWrp {
    position: relative;
    overflow: hidden;
    overflow-x: auto;
    table {
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      line-height: 42px;
      border-radius: 10px;
      @include respond-to(media-lg) {
        width: 1100px;
      }

      th {
        color: rgba(4, 35, 54, 0.53);
        background: #ffe9c6;
        padding: 10px 26px;
        &:first-child {
          border-radius: 10px 0 0 0;
        }
        &:last-child {
          border-radius: 0 10px 0 0;
        }
      }
      td {
        background: #fff;
        border-top: 1px solid #dedede;
        color: #000;
        padding: 14px 26px;
      }
      img {
        vertical-align: middle;
      }
      tr:last-child {
        td {
          border-bottom: 1px solid #dedede;
          &:first-child {
            border-radius: 0 0 0 10px;
          }
          &:last-child {
            border-radius: 0 0 10px 0;
          }
        }
      }
    }
    .catPara {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      img {
        width: 37px;
        height: 37px;
        margin-right: 15px;
      }
    }
    .tblBtnWrp {
      padding: 10px 15px;
      display: -webkit-flex;
      border: 0.5px solid #a5a5a5;
      display: flex;
      align-items: center;
      width: 155px;
      justify-content: space-between;
      border-radius: 4px;
      .Btn {
        background: transparent;
        border: none;
        outline: none;
        display: inline-block;
        width: 30px;
        height: 30px;
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        align-items: center;
        &:nth-child(2n) {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            height: 25px;
            width: 1px;
            background: #b3b3b3;
            top: 50%;
            left: -9px;
            transform: translateY(-50%);
          }
          &::after {
            content: "";
            position: absolute;
            height: 25px;
            width: 1px;
            background: #b3b3b3;
            top: 50%;
            right: -9px;
            transform: translateY(-50%);
          }
          &:last-child::after {
            display: none;
          }
        }
        img {
          vertical-align: middle;
        }
      }
    }
  }
  .listTablWrp {
    background: #fff;
    border-radius: 10px;
    padding-bottom: 20px;
  }
  .tblPagiWrp {
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    @include respond-to(media-sm) {
      justify-content: center;
    }

    .pagination {
      padding: 20px 26px 0;
      margin: 0;
      @include respond-to(media-sm) {
        padding: 20px 15px 0;
        justify-content: center;
      }
      li {
        padding: 0;
        a {
          display: inline-block;
          padding: 6px 12px;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.2;
          border: none;
        }
      }
    }
  }
}
.filBtnWrp {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  .sortBtn {
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #000000;
    background: transparent;
    border: none;
    margin-right: 35px;
    img {
      margin-right: 8px;
    }
  }
}
.whtBoxWrpper {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    width: 49.2%;
    padding: 20px 30px;
    border-radius: 8px;
    opacity: 1;
    background: #ffffff;
    min-height: 100px;
  }
}
.wbx_Cont {
  .form_grpWrp {
    padding-bottom: 20px;
    position: relative;
  }
  label {
    font-size: 15px;
    font-weight: 600;
    line-height: 32px;
    .req {
      color: #db4b4b;
    }
  }
  .form_grp {
    width: 100%;
  }
  .form_ctrl {
    width: 100%;
    font-size: 16px;
    padding: 14px 17px;
    height: 50px;
    font-weight: 600;
    line-height: 1.2;
    color: #9b9b9b;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
  }
  textarea {
    &.form_ctrl {
      height: 250px;
      resize: none;
    }
  }
}
.cusSel {
  background: url(../../../assets/images/dropdown_arr.svg) no-repeat center !important;
  background-size: 14px;
  background-position: 97% center !important;
}
.errormsg {
  display: inline-block;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  color: #9b9b9b;
}
.upldCont {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  // flex-direction: row-reverse;
  gap: 15px 3.2%;
  padding-top: 10px;

  & > div {
    width: 31.2% !important;
    height: 230px;
    border-radius: 15px;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #d7d7d7;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
  }
  .form_grp {
    .cusImgUpld {
      border: 2px dashed #2377fc !important;
    }
  }
  .custom-file-button {
    border: none !important;
    height: 100% !important;
    background: transparent !important;
    p {
      font-size: 11px !important;
      font-weight: 600;
      line-height: 15px !important;
      text-align: center;
      color: #575864;
      margin: 16px 0 0 !important;
    }
  }
  .custom-file-label {
    height: 100%;
  }
  .uploadedImgW {
    width: 141px;
    height: 141px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
}
.twnFldW {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    width: 48.2%;
  }
}
.sizeBxW {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  gap: 8px 12px;
  padding-top: 12px;
  .sizeBx {
    width: 30.7%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 42px;
      text-align: center;
      color: #000000;
      margin: 0;
    }
  }
}
.productBtnWrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0 19px;
  padding-top: 40px;
  .BtnB {
    width: 30.7%;
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    padding: 10px;
    text-align: center;
    color: #01458b;
    border-radius: 4px;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #01458b;
    transition: all 0.5s ease;
    &:hover {
      background: #01458b;
      color: #fff;
    }
  }
  .blB {
    background: #01458b;
    color: #fff;
    &:hover {
      color: #01458b;
      background: #fff;
    }
  }
}

// modal css
// /* Modal Background */
/* Modal */
.catagorylistmodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  overflow: auto;
}

/* Modal Content */
.catagorylistmodal-content {
  position: relative; /* Ensure positioning for the cancel icon */
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Cancel Icon */
.cancel-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px; /* Adjust size of the icon */
  color: #999; /* Change to any color you want */
  cursor: pointer;
  transition: color 0.3s ease;
}

.cancel-icon:hover {
  color: #333; /* Darker color on hover */
}

/* Form */
.category-form {
  display: flex;
  flex-direction: column;
}

.category-form label {
  font-size: 16px;
  color: #555;
  margin-bottom: 5px;
}

.category-form input {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  outline: none;
}

.category-form input:focus {
  border-color: #0088cc;
  box-shadow: 0 0 5px rgba(0, 136, 204, 0.3);
}

/* Action Buttons */
.catagorylistmodal-actions {
  display: flex;
  justify-content: space-between;
}

/* Save Button */
.save-btn {
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #01458b;
  color: white;
  transition: background-color 0.3s ease;
}

.save-btn:hover {
  background-color: rgb(117, 168, 249);
}

/* Modal Overlay */
.catagorylistmodal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}
.attribute-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; /* Important for positioning the delete button */
}

.attribute-field {
  margin-bottom: 10px;
}

.delete-attribute-btn {
  position: absolute; /* Position the button in front of the input */
  right: 10px; /* Adjust based on how much space you need */
  top: 35%;
  transform: translateY(-50%); /* Vertically center the button */
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1; /* Ensure button is above input field */
}

.delete-attribute-btn img {
  width: 20px;
  height: 20px;
}

input[type="text"] {
  padding-right: 30px; /* Add some padding to the right to give space for the button */
}

// add product dyamic attribute value caa
.entered-values {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  .value-tag {
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 17px;
    border: 1px solid rgb(95, 95, 95);
    display: flex;
    justify-content: center;
  }
}
.remove-icon {
  cursor: pointer;
  color: red;
  margin-left: 8px;
  font-size: 18px;
}
.remove-icon:hover {
  color: darkred;
}

.remove-icon-image {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 16px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 50px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #01458b;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

// inventory status css
.status-badge {
  display: flex;
  padding: 2px 20px;
  border-radius: 12px;
  color: white;
  font-size: 12px;
  height: 10%;
  justify-content: center;
  align-items: center;
}

.out-of-stock {
  background-color: rgb(255, 74, 74);
}

.available {
  background-color: rgb(35, 145, 35);
}

.not-available {
  background-color: #f0b342;
  margin-top: auto;
  margin-bottom: auto;
}

.orderTableminweidth {
  min-width: 120px;
}

.orderStatus select {
  background: #fff url(../../../assets/images/dropdown_arr.svg) no-repeat right 4px center;
  padding: 0 20px 0 10px;
  border-radius:15px;
  color: white;
  font-size: 0.85rem;
  text-align: center;
}

// order batches
.status-badge {
  display: inline-block;
  padding: 0px 20px;
  border-radius: 15px;
  color: white;
  font-size: 0.85rem;
  text-align: center;
  white-space: nowrap;
}

.status-completed-delivered {
  background-color: #28a745 !important;
}

.status-pending {
  background-color: #ffc107 !important;
}

.status-failed-cancelled {
  background-color: #dc3545 !important;
}

.status-default {
  background-color: #adb5bd !important;
}
