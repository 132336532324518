
@import '../../mixin.scss';
.bannerCon{

     background: url('https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/banner_img.png') no-repeat right center;
}

.event_rgt{
    background:  no-repeat center;
}

.comEventBox li .inner .event_rgt {
    width: calc(100% - 185px) !important;
    background-size: cover !important;
}

  
.flex-container{

    display: flex;
    background-color: #014d9b;
    margin-bottom: 10px;
}

.flex-container > div {
    background-color: #f1f1f1;
    margin: 10px;
    padding: 20px;
    font-size: 15px;

  }

.newsSlider {
    .slick-track {margin: 0;}
    .item {
        .inner {
            &:hover {
                .highlightPoints {opacity: 1; transform: scale(1);}
            }
        }
    } 
    .highlightPoints {position: absolute; transition: ease-in-out .5s; opacity: 0; transform: scale(0); left: 0; top: 0; width: 100%; height: 100%; overflow: auto; background: rgba(0,0,0,.8);
        ul {padding: 20px;
            li {position: relative; color: #fff; font-size: 18px; padding: 4px 0 4px 15px;
                &:before {content: ''; width: 6px; height: 6px; background: #fff; border-radius: 100%; position: absolute; left: 0; top: 12px;}
            }
        }
    }
}


.textlistBox {margin: -10px; padding: 20px 0; display: flex; flex-wrap: wrap;
    li {width: 25%; padding: 10px;
        @include respond-to(media-md) {width: 50%;}
        @include respond-to(media-xs) {width: 100%;}
        // .inner {background: #fff; height: 100%; padding: 10px; border: 1px solid #ccc;
            .inner{ background: var(--Gray-Scale-White, #FFF);
                box-shadow: 0 0 56px #00260314;
                padding: 14px;
                height: 100%;
                border-radius: 8px;
            .image {background: #000;height: 169px; display: flex; align-items: center; overflow: hidden;justify-content:center;
                img {height: 100%;}
            }
            .text {font-size: 15px; line-height: 22px; max-height: 200px; overflow: auto; margin-top: 15px;
                &::-webkit-scrollbar {width: 6px; height: 6px;}
                &::-webkit-scrollbar-track { background: #fff; }
                &::-webkit-scrollbar-thumb { background: #eee;}
                &::-webkit-scrollbar-thumb:hover { background: #ccc;}
                h3 {font-size: 17px;}
                strong {font-weight: 600;}
            }
        }
    }
}

.highligthsButton{

    left: 50%;
    bottom:20px;
    color: #fff;
    background-color: #014d9b;
    border: 4px solid #014d9b;
    border-radius: 8px;
    padding: 2px;
    position: absolute;
}

.highlightBox {display: flex; flex-wrap: wrap; justify-content: space-between;
    .points {width: 34%; padding-right: 30px;
        @include respond-to(media-md) {width: 45%;}
        @include respond-to(media-sm) {width: 100%;}
        ul {
            li {padding: 5px 0 5px 15px; font-size: 20px; position: relative;
                &:before {content: ''; width: 6px; height: 6px; border-radius: 100%; background: #666; position: absolute; left: 0; top: 16px;}
            }
        }
    }
    .highlightImg {width: 66%; margin: -10px; display: flex; flex-wrap: wrap;
        @include respond-to(media-md) {width: 55%; margin: -5px;}
        @include respond-to(media-sm) {width: 100%; padding-top: 15px;}
        .img {width: 50%; padding: 10px;
            @include respond-to(media-md) {width: 100%; padding: 5px;}
            img {border: 1px solid #ccc; padding: 2px;}
        }
    }

}

.eventTable {width: 100%; overflow: auto;
    table {width: 100%; min-width: 767px;
        th {text-align: left; background: #e5e5e5; padding: 10px; font-weight: 600;}
        td {text-align: left; border-bottom: 1px solid #dcdcdc; padding: 15px 10px; font-weight: 500;
            a.view {color: #e32c3f;}
        }
    }
}

.paging {display: flex; justify-content: flex-end; padding-top: 20px;
    ul {display: flex;
        li {margin-left: 8px;
            a {display: flex; align-items: center; justify-content: center; font-size: 14px; width: 32px; height: 32px; border-radius: 100%; border: 1px solid #ccc;
                &:hover, &.active {background: #787878; border-color: #787878; color: #fff !important;}
            }
        }
    }
}

.homeVideo {position: fixed; left: 0; top: 0; width: 100%; height: 100%; background: #000; z-index: 1000;}
.homeVideo.hidden {
    display: none;
  }

.homeVideoiIner {width:100%; margin: auto; }
.videoInner {position: relative; padding-bottom: 56.25%;}
.videoFile {position: absolute; top: 0; left: 0; width: 100%; height: 100%; border:none;}

.text{
    p{
        margin-bottom: 0rem;
    }
    img{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
}

.download-links{
    // position: ;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .playstore-img{
        height: 80px;
        width: 160px;
    }
    .appstore-img{
        height: 75px;
        width: 180px;
    }
}