@import '../../../mixin.scss';
.stLeft {width: 70%; padding: 20px;
    @include respond-to(media-lg) {width: 100%;}
    @include respond-to(media-sm) {padding: 10px;}
    .interfaceTable {
        td {font-size: 14px;
            .title {color: #0095fd; font-weight: 700; min-width: 150px;}
            .dec {min-width: 220px;}
        }
    }
}

.contentImage img{

    height: 100px;
    width:100px;
}
.uploadImg{
    img {width: 160px; border-radius: 4px;
        @include respond-to (media-sm) {width: 120px;}  
    }
}
.actionBtn {
    button {color: #919293; margin-right: 10px; background: none;
        &:hover {color: #0095fd;}
    }
}

.stRight {width: calc(30% - 20px); padding: 20px;
    @include respond-to(media-lg) {width: 100%; margin-top: 20px;}
    @include respond-to(media-sm) {padding: 10px;}
    .editForm {
        li {width:100%;}
    }
    .dbSubHd {
        @include respond-to(media-sm) {font-size: 20px; padding-bottom: 10px;}  
    }
}
.formBtn {
    &.full {width: 100%;}
}
.space{
    margin-top: 60px;
}
