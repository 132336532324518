@import '../../mixin.scss';
.top flexBox{background: #ffd3cd;}
.noEvent{
    border: 1px solid #eee;
    padding: 15px;
    text-align: center;
    color: #ff0000;
    font-size: 18px;
}
.spinner-border{

    width: 3rem !important;
    height: 3rem !important;
}

.upcomingEventList {
    li {border:1px solid #ffd3cd; border-radius: 6px; overflow: hidden; padding: 15px; position: relative; display: flex; align-items: center; flex-wrap: wrap; font-family: 'Poppins', sans-serif; margin-bottom: 10px;
        &:before {content: ''; background: #fff4f2; width: 10px; height: 100%; position: absolute; left: 0; top: 0;}
        .name {color: #014d9b; font-size: 17px; font-weight: 700; padding: 0 10px; width: 25%;
            @include respond-to(media-md) {font-size: 15px;}
            @include respond-to(media-sm) {width: 100%;}
        }
        .Info {color: #000; font-size: 15px; font-weight: 600; padding: 0 10px; width: 30%;
            @include respond-to(media-md) {font-size: 14px;}
            @include respond-to(media-sm) {width: 100%; padding-top: 5px;}
        }
        .date {color: #000; font-size: 17px; font-weight: 600; padding: 0 10px; width: 30%;
            @include respond-to(media-md) {font-size: 15px;}
            @include respond-to(media-sm) {width: 100%; padding-top: 5px;}
            span {color: #85726f; display: block; font-size: 13px;}
        }
        .location {color: #00abeb; font-size: 14px; font-weight: 600; padding: 0 10px; width: 15%; text-align: right;
            @include respond-to(media-sm) {width: 100%; padding-top: 5px; text-align: left;}
        }
        &.blue {border:1px solid #b2c9e1;
            &:before {background: #e7f1f8;}
        }
    }
}
.upcomingEventList{
    li{
        .nameup{
            width: 20%;
        }
        .Infoup{
            width:23%
        }
        .dateup{
            width: 27%;font-size: 15px;
        }
        .locationup{
            width: 15%;
        }
        .fbicon{
            width: 15%;
            padding-left: 50px;
        }
    }
}

.eventContent{

    margin-top: 10px;
    font-size: 15px;
    font-weight: 500;
    display: block;
}


