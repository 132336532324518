
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
    *, body{
        padding:0;
        margin:0; 
        box-sizing: border-box; 
        font-family: "Inter", sans-serif;
    }
    h1,h2,h3,h4,h5,h6{padding:0;margin:0; font-family: "Inter", sans-serif; }
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .clearfix:after,.clearfix:before{content:'';display:block;height:0;overflow:hidden}
.clearfix:after{clear:both}
.p-0{
    padding-bottom: 0 !important;
}
.ContactOuter{
    background: #fff;
}

.container{
    max-width: 1390px;
    margin: 0 auto;
    padding: 0 10px;
}
.wishlistOuter{
    padding: 50px 0;
}
.policyHd {
    font-size: 28px;
    font-weight: 800;
    padding-bottom: 10px;
}
.policyCont {
    padding-bottom: 20px;
    color: #1e1e1e;
}
.policyCont h4 {
    font-size: 15px;
    padding-bottom: 7px;
}
.policyCont p {
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 20px;
}
.policyCont ul {
    list-style: disc;
    margin-left: 25px;
    font-size: 14px;
    padding-bottom: 10px;
}
.policyCont ul li {
    padding-bottom: 4px;
    line-height: 20px;
}
.policyHd strong {
    font-size: 18px;
    display: block;
    padding: 5px 0 10px 0;
    font-weight: 500;
    color: #666;
}
.policyCont ol {
    padding-left: 25px;
    font-size: 14px;
}
.policyCont ol li {
    padding-bottom: 5px;
}
.policyCont ol li a {
    font-size: 14px;
    color: #0099ff;
}
.policyCont h3 {
    font-size: 17px;
    padding-bottom: 10px;
}
.policyCont h5 {
    font-size: 15px;
    padding-bottom: 10px;
    text-decoration: underline;
    font-weight: 500;
}
.policyCont table {
    border-left: #ccc solid 1px;
    border-top: #ccc solid 1px;
    width: 100%;
}
.policyCont table th {
    border-right: #ccc solid 1px;
    border-bottom: #ccc solid 1px;
    padding: 10px;
    font-weight: 600;
    text-align: left;
}
.policyCont table td {
    border-right: #ccc solid 1px;
    border-bottom: #ccc solid 1px;
    padding: 10px;
    text-align: left;
}

