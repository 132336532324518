@import "../../../mixin.scss";
.centerStyle {
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  height: '100vh';
};
/* Add this CSS in your style file or style tag */
@keyframes pulse {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.loading-dots {
  display: inline-block;
  animation: pulse 1.5s infinite;
}

.mhLeft {
  width: 380px;

  @include respond-to(media-lg) {
    width: 320px;
  }

  @include respond-to(media-md) {
    width: 100%;
  }

  .dbMainHd {
    padding: 0;
  }

  .text {
    font-weight: 600;
  }
}

.heatBox {
  background: #fff;
  border-radius: 8px;
  padding: 25px;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  line-height: 26px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  img {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    margin: 0 auto 20px auto;
  }

  span {
    display: block;
    font-weight: 700;
  }
}

.eventClassName {
  font-size: 15px;
  font-weight: 600;
}

.mhRight {
  width: calc(100% - 380px);
  padding-left: 25px;

  @include respond-to(media-lg) {
    width: calc(100% - 320px);
    padding-left: 20px;
  }

  @include respond-to(media-md) {
    width: 100%;
    padding: 20px 0 0 0;
  }
}

.mhrTop {
  border-bottom: 1px solid #d3d5dc;
  padding-bottom: 10px;
  font-family: "Poppins", sans-serif;

  .left {
    @include respond-to(media-xs) {
      width: 100%;
    }
  }

  .formSelect {
    padding: 10px 25px 10px 10px;

    &.class {
      width: 200px;
      margin-right: 20px;

      @include respond-to(media-xs) {
        width: 48%;
        margin-right: 4%;
      }
    }

    &.round {
      width: 150px;

      @include respond-to(media-xs) {
        width: 48%;
      }
    }
  }

  a.button {
    margin: 10px;
    display: inline-block;
    background: #0095fd;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    padding: 10px 35px;

    &:hover {
      background: #000;
    }

    @include respond-to(media-xs) {
      margin-top: 10px;
    }
  }
}

.sheetList {
  margin: -12px;
  padding: 17px 0 0 0;
  width: 1146px;

  @include respond-to(media-lg) {
    margin: -10px;
  }

  &.column2 {
    .box {
      width: 21%;
    }
  }

  &.column3 {
    .box {
      width: 43%;
    }
  }

  .box {
    width: 25%;
    padding: 13px;
    margin: 15px;

    @include respond-to(media-xl) {
      width: 25%;
    }

    @include respond-to(media-lg) {
      width: 25%;
      padding: 10px;
    }

    @include respond-to(media-xs) {
      width: 50%;
    }

    .inner {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      overflow: hidden;

      .hd {
        background: #01458b;
        padding: 10px 15px;
        font-size: 17px;
        color: #fff;
      }

      .list {
        padding: 15px;
        padding: 15px;
        height: 227px;
        overflow: auto;

        .listItem {
          background: #f0f0f0;
          border-radius: 4px;
          padding: 10px 90px 10px 15px;
          margin-bottom: 10px;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
          position: relative;
          font-size: 17px;
          font-weight: 600;

          .dragIcon {
            position: absolute;
            right: 10px;
            top: 10px;
            color: #9c9c9c;
          }

          .toggleSwitch {
            position: absolute;
            right: 45px;
            top: 10px;
          }
        }
      }

      .list1 {
        padding: 15px;
        padding: 15px;
        overflow: auto;
        height: 351px;

        .listItem {
          background: #f0f0f0;
          border-radius: 4px;
          padding: 10px 90px 10px 15px;
          margin-bottom: 10px;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
          position: relative;
          font-size: 17px;
          font-weight: 600;

          .dragIcon {
            position: absolute;
            right: 10px;
            top: 10px;
            color: #9c9c9c;
          }

          .toggleSwitch {
            position: absolute;
            right: 45px;
            top: 10px;
          }
        }
      }
    }
  }
}

.sheetList1 {
  margin: -12px;
  padding: 17px 0 0 0;

  @include respond-to(media-lg) {
    margin: -10px;
  }

  &.column2 {
    .box {
      width: 50%;
    }
  }

  &.column3 {
    .box {
      width: 43%;
    }
  }

  .box {
    width: 25%;
    padding: 12px;

    @include respond-to(media-xl) {
      width: 25%;
    }

    @include respond-to(media-lg) {
      width: 25%;
      padding: 10px;
    }

    @include respond-to(media-xs) {
      width: 50%;
    }

    .inner {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      overflow: hidden;

      .hd {
        background: #01458b;
        padding: 10px 15px;
        font-size: 17px;
        color: #fff;
      }

      .list {
        padding: 15px;
        padding: 15px;
        height: 227px;
        overflow: auto;

        .listItem {
          background: #f0f0f0;
          border-radius: 4px;
          padding: 10px 90px 10px 15px;
          margin-bottom: 10px;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
          position: relative;
          font-size: 17px;
          font-weight: 600;

          .dragIcon {
            position: absolute;
            right: 10px;
            top: 10px;
            color: #9c9c9c;
          }

          .toggleSwitch {
            position: absolute;
            right: 45px;
            top: 10px;
          }
        }
      }

      .list1 {
        padding: 15px;
        padding: 15px;
        overflow: auto;
        height: 351px;

        .listItem {
          background: #f0f0f0;
          border-radius: 4px;
          padding: 10px 90px 10px 15px;
          margin-bottom: 10px;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
          position: relative;
          font-size: 17px;
          font-weight: 600;

          .dragIcon {
            position: absolute;
            right: 10px;
            top: 10px;
            color: #9c9c9c;
          }

          .toggleSwitch {
            position: absolute;
            right: 45px;
            top: 10px;
          }
        }
      }
    }
  }
}

.compareBox {
  justify-content: center;
  padding: 20px;
}

.opcheckWrapper {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 4px 0;
}

.opcheckWrapper .checkInpt {
  opacity: 0;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  margin: 0;
}

.opcheckWrapper .checkInpt + .bg {
  height: 24px;
  width: 24px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border: 2px solid #0095fd;
  background: #fff;
  border-radius: 100%;
}

.opcheckWrapper .checkInpt:checked + .bg {
  background: #0095fd url(https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/check_icon.png) no-repeat
    center;
}

.opcheckWrapper1 {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 90%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 4px 0;
}

.opcheckWrapper1 .checkInpt {
  opacity: 0;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  margin: 0;
}

.opcheckWrapper1 .checkInpt + .bg {
  height: 24px;
  width: 24px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border: 2px solid #0095fd;
  background: #fff;
  border-radius: 100%;
}

.opcheckWrapper1 .checkInpt:checked + .bg {
  background: #0095fd url(https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/check_icon.png) no-repeat
    center;
}

.GenerateHeatBtn {
  position: relative;
  left: 10px;
}

.mergeBtn {
  a {
    display: block;
    background: #fff;
    border-radius: 4px;
    padding: 3px 10px;
    color: #01458b;
    margin-left: 5px;
  }
}

.mText {
  font-size: large;
  font-weight: 500;
}

.Header {
  font: 1em sans-serif;
}

.cName {
  color: #01458b;
}

.heatNo {
  color: #000;
  font-style: italic;
}

.trRight {
  position: relative;
}

.masterTable {
  width: 100%;
  overflow: auto;
  font-family: "Poppins", sans-serif;
  padding: 0px;

  table {
    width: 100%;
    border-spacing: 0px;
    border-collapse: initial;

    th {
      background: #d2d0d0;
      padding: 5px 4px;
      font-size: 8px;
      font-weight: 700;
      text-transform: uppercase;
    }

    td {
      background: #fff;
      padding: 5px 4px;
      font-size: 8px;

      &.gray {
        background: #fff;
        font-weight: 700;
        text-align: center;
      }

      &.grayTotal {
        background: #e8e7e7;
        font-weight: 700;
        padding: 5px 7px;
      }

      &.hd {
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        padding: 5px 7px;
      }

      table {
        background: #d2d0d0;
        border-spacing: 2px;
      }
    }
  }
}



//css for fees download tabe pdf
.masterTable2 {
  // width: 100%;
  overflow: auto;
  font-family: "Poppins", sans-serif;
  padding: 0px;

  table {
    width: 100%;
    margin: 0 auto;
    border-spacing: 0px;
    border-collapse: initial;

    th {
      background: #d2d0d0;
      padding: 5px 4px;
      font-size: 8px;
      font-weight: 700;
      text-transform: uppercase;
    }

    td {
      background: #fff;
      padding: 5px 4px;
      font-size: 8px;

      &.gray {
        background: #fff;
        font-weight: 700;
        text-align: center;
      }

      &.grayTotal {
        background: #e8e7e7;
        font-weight: 700;
        padding: 5px 7px;
      }

      &.hd {
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        padding: 5px 7px;
      }

      table {
        background: #d2d0d0;
        border-spacing: 2px;
      }
    }
  }
}




.roun {
  width: 16% !important;
}

.scr {
  width: 4% !important;
}

.downloadButton {
  margin-left: 10px;
  display: inline-block;
  background: #0095fd;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  padding: 10px 35px;

  &:hover {
    background: #000;
  }

  @include respond-to(media-xs) {
    margin-top: 10px;
  }
}

.chatBox {
  position: fixed;
  right: 5px;
  bottom: 5px;
  z-index: 10001;

  &.active {
    .chatTop {
      display: block;
    }
  }
}

.chatTop {
  position: absolute;
  right: 0;
  bottom: 75px;
  width: 550px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: none;
}

.chatLeft {
  width: 190px;
  background: #eaedf4;

  .hd {
    border-bottom: 1px solid #cccc;
    height: 44px;
    padding: 0 15px;
    line-height: 44px;
    color: #000;
    font-weight: 700;
    font-size: 18px;
  }

  .chatUserList {
    height: 445px;
    overflow: auto;

    li {
      padding: 8px 12px;
      color: #000;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background: #fff;
      }

      i {
        display: block;
        width: 24px;
        min-width: 24px;
        height: 24px;
        background: #000;
        color: #fff;
        border-radius: 100%;
        margin-right: 8px;
        font-style: normal;
        text-align: center;
        line-height: 24px;
      }
    }
  }
}

.centerh {
  text-align: center;
}

.chatRight {
  width: calc(100% - 190px);

  .hd {
    border-bottom: 1px solid #cccc;
    height: 44px;
    padding: 0 15px;
    line-height: 44px;
    color: #000;
    font-weight: 700;
    font-size: 18px;
  }
}

.messageList {
  height: 400px;
  overflow: auto;
  padding: 15px;

  ul {
    li {
      margin-bottom: 10px;
      display: flex;

      .inner {
        background: #eee;
        padding: 10px;
        font-size: 14px;
        width: 70%;
        position: relative;

        .moreLink {
          position: absolute;
          right: -25px;
          bottom: 0;

          a {
            color: #999;
          }
        }
      }

      &.left {
        .inner {
          background: #e8e8e8;
          border-radius: 10px 10px 10px 0;
        }
      }

      &.right {
        justify-content: flex-end;

        .inner {
          background: #d9dffd;
          border-radius: 10px 10px 0 10px;
        }
      }

      .top {
        margin-bottom: 5px;

        .name {
          color: #000;
          font-weight: 600;
        }
      }

      .userIcon {
        background: #10d9d9;
        width: 32px;
        height: 32px;
        min-width: 32px;
        border-radius: 100%;
        margin-right: 5px;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        text-align: center;
        line-height: 32px;
      }
    }
  }
}

.actionBtns {
  button {
    margin-left: 5px;
    background: none;
    line-height: normal;
  }
}

.chatMessage {
  border-top: 1px solid #ccc;
  position: relative;
  padding-right: 80px;

  .input {
    width: 100%;
    height: 44px;
    border: none;
    background: none;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;
  }

  .sendBtn {
    position: absolute;
    right: 15px;
    top: 50%;
    width: 65px;
    background: #0095fc;
    height: 28px;
    color: #fff;
    font-weight: 600;
    border-radius: 30px;
    margin-top: -14px;
    text-transform: uppercase;
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.containerz {
  page-break-inside: avoid;
  height: calc(11in - (1in + 0.5in + 0.5in));
  /* Subtract the height of any elements above the container div and any margins and padding */
}

.page-template {
  font-size: 12px;
  color: #333;
  /* Add any other styles you want */
}

.downloadRef {
  padding: 10px;
}

.tblewidth {
  page-break-inside: avoid;
  // height: calc(11in - (1in + 0.5in + 0.5in)); /* Subtract the height of any elements above the container div and any margins and padding */
}

#heatOptions .swal-button {
  width: 12rem;
}

.swal-button {
  width: 12rem;
}

.swal-inputsbutton {
  input {
    position: fixed;
    display: list-item;
    margin: 4px 131px;
  }
}
.centerStyle {
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  height: '100vh';
};
.cell-#ABCDEF {
  background-color: #ABCDEF;
}
.renumber-heats{
  margin-left: 10px;
  display: inline-block;
  background: #0095fd;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  padding: 8px 35px;

  &:hover {
    background: #000;
  }
}