@import '../../mixin.scss';
@import 'react-accessible-accordion/dist/fancy-example.css';

.innerBanner.event {background: url(https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/event_banner.jpg) no-repeat center;}
.tabCover {position: relative;
    .blueBtn {cursor: pointer;position: absolute; background: #00abeb; right: 20px; bottom:12px; color: #fff; border-color: #00abeb;
        @include respond-to(media-sm) {position: static; margin: 10px;cursor: pointer;}
        &:hover { cursor: pointer;background: #014d9b; border-color: #014d9b;}
    }
}
.aa{

    white-space: pre-line;
}

.eventDetLeft {width: 380px; background: #f6f6f6; font-family: 'Poppins', sans-serif;
    @include respond-to(media-md) {width: 300px;}
    @include respond-to(media-sm) {width: 100%;}
    .inner {border-bottom: 1px solid #c7c8c8; padding: 25px;
        @include respond-to(media-md) {padding: 15px;}
        &:last-child {border-bottom: none;}
    }

    .evdetTop {
        .img {
            img {border-radius: 100%; width: 135px; height: 135px;}
        }
        .text {font-size: 15px; line-height: 28px; padding-top: 15px;
            span {display: block; font-weight: 700;}
        }
    }
    .location  {font-size: 15px; line-height: 28px; padding-top: 15px;
        span {display: block; font-weight: 700;}
        a {color: #014d9b; font-weight: 600;}
        button{
            color: #014d9b; font-weight: 600;
            background-color: #f6f6f6;
        }
    }
}
.raceRyt2{background: #f6f6f6; font-family: 'Poppins', sans-serif;
    @include respond-to(media-md) {width: 300px;}
    @include respond-to(media-sm) {width: 100%;}
    .inner {border-bottom: 1px solid #c7c8c8; padding: 25px;
        @include respond-to(media-md) {padding: 15px;}
        &:last-child {border-bottom: none;}
    }

    .evdetTop {
        .img {
            img {border-radius: 100%; width: 135px; height: 135px;}
        }
        .text {font-size: 15px; line-height: 28px; padding-top: 15px;
            span {display: block; font-weight: 700;}
        }
    }
    .location  {font-size: 15px; line-height: 28px; padding-top: 15px;
        span {display: block; font-weight: 700;}
        a {color: #014d9b; font-weight: 600;}
    }
}
.eventDetRight {width: calc(100% - 380px); padding: 25px; font-family: 'Poppins', sans-serif;
    @include respond-to(media-md) {width: calc(100% - 300px); padding: 15px;}
    @include respond-to(media-sm) {width: 100%;}
    .hd {font-size: 14px; font-weight: 700; display: block; padding-bottom: 10px;}
    .list {
        li {padding: 6px 0 6px 32px; font-size: 14px; position: relative;
            &:before {content:'east'; font-family: 'Material Icons'; position: absolute; left: 0; top: 5px; font-size: 22px; width: 22px; height: 22px;}
        }
    }
}

.eventDetBot {border-top: 1px solid #cecfcf; padding: 25px; font-family: 'Poppins', sans-serif; font-size: 15px;
    @include respond-to(media-md) {padding: 15px;}
    .hd {font-weight: 700;}
    .districtSelectBox  {font-weight: 500;
        select {width: 180px;}
    }
}

.eventDetBot {
    .accordion {border: none; border-radius: 0;}
    .accordion__item {margin-bottom: 10px;}
    .accordion__button {padding: 6px 40px 6px 15px; color: #000; background: #ecebeb; font-size: 16px; font-weight: 700; text-transform: uppercase; position: relative;
        @include respond-to(media-sm) {font-size: 14px; padding: 6px 30px 6px 10px;}
        &:before {display: none;}
        &:after {content:'+'; position: absolute; right: 10px; top: 4px; font-size: 22px; font-weight: 600;
            @include respond-to(media-sm) {top: 1px; right: 5px;}
        }
    }

    .accordion__button[aria-expanded='true']::after, .accordion__button[aria-selected='true']::after {content: '-';}
    .accordion__panel {padding: 10px;}
}
.acctable {
    table {width: 100%;
        td {padding: 2px 5px; font-size: 13px; font-weight: 500;}
    }
}