@import '../../../mixin.scss';

.selectEvent {
  width: 50%;
  max-width: 360px;
}

.eventDate {
  color: #014d9b;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.eventTime {
  color: #014d9b;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 30px;
}

.editForm {
  &.full {
    max-width: inherit;
  }
}

.interfaceTable {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  overflow: auto;

  table {
    width: 100%;

    th {
      padding: 10px;
      font-weight: 700;
      border-bottom: 1px solid #c3c5d0;
      white-space: nowrap;
    }

    td {
      background: #f2f3f5;
      border-bottom: 1px solid #c3c5d0;
      padding: 20px 10px;
    }

    tr {
      &:nth-child(even) td {
        background: #fff;
      }
    }
  }
}


.App {
  width: 100%;
  margin: 0 auto;
}

.drag-container {
  text-align: center;
}

.head {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: #CCCCCC;
  width: 100%;
}

.droppable {

  width: 255px;
  min-height: 100px;
  right: 0;
  top: 10;
  background-color: #1c60a6;
  border-left: 2px dotted red;
  margin: 10px;
  text-align: center;
  //overflow-y: scroll;
  //overflow: hidden;

}

.cancelledClassMsg {

  font-weight: bold;
  color: red;

  right: 0;
}

.cancelledPopup {

  background-color: #1c60a6;
  border: none;
  color: white;
  padding: 10px 31px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 3px;
}

.yesPopup {

  background-color: #a6431c;
  border: none;
  color: white;
  padding: 10px 31px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 3px;
}

.noPopup {

  background-color: #8d7355;
  border: none;
  color: white;
  padding: 10px 31px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 3px;
}

.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}


.draggable {

  background-color: #fff;
  margin: 10px auto;
}

// li[draggable] {
//   cursor: move;
// }
// li[draggable]:active {
//   opacity: 0.6;
// }
// li[draggable]:active {
//   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); /* Adjust the 5px value as needed */
// }

// li {
//   transition: opacity 0.2s ease-in-out;
// }
// .droppable:hover {
//   background-color: #f0f0f0;
// }




.inProgress {
  position: absolute;
  width: 200px;
  height: 200px;
  left: 0;
  top: 10;
  background-color: #EEEEEE;
  border-right: 2px dotted red;
}

.crossButton {



  color: red;
  cursor: pointer;
  margin-right: 0;
  position: relative;
  float: right;
}

//  body {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
// }

.list {
  list-style: none;
  width: 500px;
  margin: auto;
}

.list li {
  cursor: move;
  background-color: black;
  color: whitesmoke;
  padding: 10px;
  margin-bottom: 1px;
  transition: all 0.2s;
}

// .list li:hover {
//   background-color: #003366 !important;
// }

.draggable-container {
  border: 1px solid #000000;
  padding: 10px;
}

// .App {
//   font-family: sans-serif;
//   text-align: center;
//   background: skyblue;
// }
// h3 {
//   font-weight: 200;
//   text-shadow: 1px 1px 3px gray;
// }
// .container {
//   height: 90vh;
//   width: 80vw;
//   margin: 0 auto;
//   padding: 10px;
//   display: flex;
//   justify-content: space-evenly;
// }
// .small-box {
//   width: 40%;
//   // box-shadow: 3px 3px 50px 2px gray;
// }

.task {
  border: 1px solid white;
  margin: 10px;
  // box-shadow: 1px 3px 20px gray;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragged {
  background-color: rgba(39, 205, 211, 0.3);
  opacity: 0.3;
  cursor: pointer;
  box-shadow: none;
}

.dragged-over {
  background: rgba(255, 0, 0, 0.2);
  box-shadow: none;

  .task {
    background: rgba(255, 0, 0, 0.1);
  }
}