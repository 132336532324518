@import '../../../mixin.scss';
.ml10 {margin-left: 10px;}
.uocomingRaces {
    li {
        .det {
            .hd {
                span.black {color: #000;}
            }
            .statsBx {padding: 5px 0 0 0;
                .formSelect {width: 150px; padding: 5px 25px 5px 5px;
                    @include respond-to (media-xs) {width: 100%;}
                }
            }
        }
        .rgt {flex-direction: column;
            .flexBox {justify-content: flex-start; width: 100%;}
            @include respond-to (media-xs) {flex-wrap: wrap; align-items: flex-start !important;}
            .smallLink {margin-top: 5px;
                @include respond-to (media-xs) {padding: 10px 0 0 0;}
                a {border: none; color: #014d9b; font-size: 16px; padding: 0; margin-left: 15px;
                span {vertical-align: middle; color: #333; font-size: 22px; width: 22px; height: 22px; margin: 0 2px 2px 0;}
                }
            }
        }
    }
}



.custom-select{
    display: inline-block;
    box-sizing: border-box;
    padding: 0.5em 2em 0.5em 0.5em;
    border: 1px solid #eee;
    font: inherit;
    line-height: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%);
    background-position: right 15px top 1em, right 10px top 1em;
    background-size: 5px 5px, 5px 5px;
  }

.react-switch.publishStatus {
    margin-top:10px;
    margin-left:10px;
}
.publishStatusText{
    margin-left: 10px;


}

.scorePopup {
    &.modal-dialog {max-width: 700px;
        .scoreInner {padding:20px 30px;
            @include respond-to (media-sm) {padding:15px;}
        }
    }
}

.scoreHeader {position: relative; display: block; padding: 0;
    .close {color: #fff; z-index: 10; position: absolute; right: 10px; top: 10px; width: 36px; height: 36px; background: #03315e; border-radius: 100%; display: flex; align-items: center; justify-content: center;}
    h5 {width: 100%;}
}
.popupHd {font-size: 25px; font-weight: 700; padding-bottom: 10px;
    @include respond-to (media-sm) {font-size: 22px;}
}
.classess {padding-bottom: 15px;
    span {display: block; font-size: 15px; font-weight: 700; padding-bottom: 5px;}
    .formSelect {max-width: 400px;}
}

.scoreTable {width: 100%; overflow: auto;
    table {width: 100%;
        th {font-size: 15px; font-weight: 700; text-align: center; padding: 5px;
            &:first-child {text-align: left;}
        }
        td {font-size: 15px; font-weight: 700; text-align: center; padding: 5px; white-space: nowrap;
            &:first-child {text-align: left; color: #01458b; font-size: 17px;
                @include respond-to (media-sm) {font-size: 15px;}
            }
            .input {width:60px; border: 1px solid #c0c0c0; border-radius: 6px; text-align: center; padding: 5px;
                &:read-only {background: #eee;}
            }
        }
    }
}

.AddBtn {text-align: right; padding: 10px 0 0 0;
    button {background: #0095fd; font-weight: 600; color: #fff; padding: 6px 35px; font-size: 17px;
        &:hover {background: #000;}
    }
}
.uocomingRaces .notEvent {

    padding: 15px;
    text-align: center;
    color: #ff0000;
    font-size: 18px;
}
.eventSelect{
    width: 25%;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: 17px;
    border: 1px solid #b0b0b0;
    font-weight: 500;
    background: #fff
   url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjFCRjU3NTExNUYxQjExRUNBNUY0OUQ2Rjg2MzQ4RjlGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjFCRjU3NTEyNUYxQjExRUNBNUY0OUQ2Rjg2MzQ4RjlGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MUJGNTc1MEY1RjFCMTFFQ0E1RjQ5RDZGODYzNDhGOUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MUJGNTc1MTA1RjFCMTFFQ0E1RjQ5RDZGODYzNDhGOUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4644a7AAABbklEQVR42mKsqKjgvHPnTq+RkdFRHR2dpV++fGHg4eFh4OfnZ/j8+TMDGxsbw48fPyQWLVrUaWBgMItZXFx8ytq1azNOnTrlaWJickVYWPgmIyMjAwcHB8Pfv39BGgR7enpWbNmyJfDZs2deTHJycqeApv0AmsbZ3Ny84MGDB/7c3NwMTExMDKysrKIdHR0r9uzZ48wABBISEqcZ+vv7GQoLCxN4eXm/A8X+i4iIfJw/f77ruXPneNzd3beDxEDY0dFxA9BAXmYHBweQey9oa2vfv3Llis/79++5gc5zP3HihO/+/fsdQCYDNa62sbGJ//Xr1xdmFxcXBiCDAWjyZXl5+afXr1/3fPv2Ld/jx49lQIrt7e03A3HC9+/fP4OcyQQSBHkSGBIMkpKS85OSkjKAfvgNEndyctoANDkKqPjT//8glzEwgG0AcZiZmRl+/vzJICQkdEFaWvodkP5lZmYWDxT7DJIDqQHZABBgAITilywF74BVAAAAAElFTkSuQmCC) no-repeat right 10px center;
}
.disabled-link {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
    color: grey;
  }
  .disabled-link, .disabled-link span {
    color: gray;
  }
  
  .trash{
    color: rgb(104, 102, 102);
    cursor: pointer;
    margin-top: 5%;
    transition: all 0.3s; 
    border-radius: 10%;
  }
  .trash:hover {
    background-color: rgb(241, 240, 240);
  }
  .swal2-confirm{
    background-color: #01458b;
  }