.searchUser{
    padding: 10px;
    /* border: none; */
    /* background: none; */
    font-size: 18px;
    font-weight: 600;
    width: 100%;
}
.button-event{
    padding: 5px;
    margin: 5px;
    background-color: #0d6efd;
    font-size:15px;
    color:#fff;
    border-radius: 10px;
    font-weight: bold;
}

.eventInner.membersList-box .eventForm {
    margin: 0px;
    border: 1px solid #eee;
    border-radius: 0px 0px 4px 4px;
    max-height: 300px;
    overflow-y: auto;
}

.eventInner.membersList-box .eventForm li {
    display: block;
    width: 100%;
    border-bottom: 1px solid #eee;
    padding: 10px;
}