.class-name-line-break {
    display: block;
    white-space: pre-wrap;
  }
  // .screimg{
  // width: 100%;
  // overflow: auto;
  // text-align: center;
  // /* border-bottom: 1px solid #cecfcf; */
  // margin-top: 25%;}
  .resultTbleImg { width: 100%;
    overflow: auto;
    text-align: center;
    border-bottom: none;
    margin-top: 25%;
}
.resultTbleImg1 { width: 100%;
  overflow: auto;
  text-align: center;
  border-bottom: none;
}
.selected{
  color:  #0095fd;
}


.dilogCon .MuiDialog-container {width: 100%;}
.dilogCon .MuiDialog-paper {max-width: 100%;}
.clubDilog {width:1000px;}
.clubDilogBox {position: relative; height: 250px; overflow: auto; text-align: center; display: flex; align-items: center; justify-content: center; padding: 0 50px;}
.clubDilogBox .img {position: absolute; left: 0; top: 0; width: 100%;}
.clubDilogBox .img img {width: 250px; height: 250px;}
.clubDilogBox .carousel-caption {position: static; padding: 10px;}
.dilogSliderBox {width:100%; }
.dilogSliderBox .carousel-control-next, .dilogSliderBox .carousel-control-prev {width: 42px; height: 42px; background: #000; top: 50%; margin-top: -21px;}
.dilogCon .MuiDialogContent-root {max-height: 70vh; overflow: auto;}

.dilogCon .MuiDialogContent-root table th {white-space: nowrap;}
.resultHd {background: #eee; padding: 10px; font-size: 16px; font-weight: 500;}