@import '../../../mixin.scss';
.uploadImg {
    img {width: 160px; border-radius: 4px;
        @include respond-to (media-sm) {width: 120px;}  
    }
}
.uploadBtn {margin-left: 10px; display: block; width: 160px; height: 44px; border: 1px solid #b0b0b0; border-radius: 6px; overflow: hidden; position: relative; text-align: center; line-height: 42px; color: #01458b; font-size: 18px; font-weight: 700;
    @include respond-to (media-sm) {width: 120px; height: 34px; line-height: 32px; font-size: 16px;}  
    .fileInput {position: absolute; left: 0; top: 0; font-size: 44px; opacity: 0;}
}
.createEventForm {margin: -10px; padding-bottom: 30px;
    &.pb10 {padding-bottom: 10px;}
    @include respond-to (media-sm) {padding-bottom: 20px; margin:-5px;}  
    li {width: 50%; padding: 10px;
        @include respond-to (media-sm) {padding: 5px;} 
        &.full {width: 100%;}
        &.entriesField {width: 20%;
            @include respond-to (media-lg) {width: 33.33%;}
            @include respond-to (media-sm) {width: 50%;}
            @include respond-to (media-xs) {width: 100%;}
        }
  
    }
}
.close-btn {
    font-size: 23px;
    font-weight: bold;
    color: red;
    cursor: pointer;
  }
.topColumn {margin:-10px;
    .topColumn1 {padding: 10px; width: 30%;
        @include respond-to (media-md) {width: 100%;
            .createEventForm {padding-bottom: 0;}
        }
    }
    .topColumn2 {padding: 10px; width: 50%;
        @include respond-to (media-md) {width: 100%;
            .createEventForm {padding-bottom: 0;}
        }
    }
    .topColumn3 {padding: 10px; width: 20%;
        @include respond-to (media-md) {width: 100%;}
    }
}

.smallForm {max-width: 620px;}
.feeBox {color: #000; font-size: 15px; font-weight: 700;
    span {margin-right:10px;}
}
.freqBox {margin: -5px -15px;
    @include respond-to (media-sm) {margin: -5px -10px;}
    label {padding: 5px 15px; font-size: 18px; color: #000; font-weight: 700;
        @include respond-to (media-sm) {padding: 5px 10px; font-size: 16px;}
    }
}
.exText {color: rgba(0,0,0,.6); font-size: 14px; padding-top: 5px;}
.addBtn {text-align: right; padding:10px 10px 0 0;
    button {background: #01458b; color: #fff; padding: 5px 20px;}
}

a.addNew {display: inline-block; margin-left: 10px; color: #0095fd; font-size: 15px; font-weight: 700; text-decoration: underline;
    @include respond-to (media-sm) {font-size: 13px;} 
}

.hotelResList {margin: -10px; padding-bottom:20px;
    li {width:20%; padding: 10px;
        @include respond-to (media-lg) {width: 33.33%;}
        @include respond-to (media-sm) {width: 50%;}
        @include respond-to (media-xs) {width: 100%;}
        .inner {border: 1px solid #725c5c; border-radius: 6px; height: 100%;
            .top {padding: 15px; font-weight: 700; font-size: 18px;}
            .bot {border-top: 1px solid #725c5c;
                a {display:block; width: 50%; padding: 10px; font-size: 15px; font-weight: 700; text-align: center; color: #0095fd;
                    &.remove {color: #fd002a; border-left: 1px solid #725c5c;}
                }
            }
        }
    }
}

.dbSubHd {
    @include respond-to (media-sm) {font-size: 18px; padding-bottom: 5px; line-height: 20px;}    
}


.addBtnNew { padding:0 0 0 10px;
    button {background: #01458b; border-radius: 6px; color: #fff; padding: 10px 30px; font-size: 18px;}
}
.infoIcon {
    color: #428bca;
    font-size: 16px;
    margin-left: 5px;
    cursor: pointer;
    font-style: italic;
  }
  .errorContainer{
    margin-left: 5px;
    color: #01458b;
  }
  .errorContainer{
    span{
        margin-left: 5px;
        font-weight:100px;
        color: red;
        font-size: 13px;
        font-weight: 400;
        font-style: italic;
    }
  }
  .email-notifications{
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 10px 0;
    input{
        height: 17px;
        width: 17px;
    }
    label{
        font-size: 17px;
        font-weight:500;
    }
  }