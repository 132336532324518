@import "../../mixin.scss";
.deakHeatOuter{
    max-width: 1020px;
    margin: 0 auto;
    padding:10px;
    h3{
        font-size: 28px;
        font-weight: 600;
        padding-bottom: 10px;
        @include respond-to(media-md) {
            font-size: 22px;
        }
        @include respond-to(media-xs) {
            font-size: 20px;
        }
    }
    .deakOuter{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 40px;
        @include respond-to(media-sm) {
            display: block;
        }
        h5{
            font-size: 20px;
            padding-bottom:10px !important;
            text-align: center;
        }
        .heatLeftBx {
            width: 250px;
            background: #fff;
            padding: 15px;
            border-radius: 6px;
            border: #e5e5e5 solid 1px;
            @include respond-to(media-md) {
                width: 180px;
                padding: 10px;
            }
            @include respond-to(media-sm) {
                margin-bottom: 20px;
                width: 100%;
            }
            button{
                width: 100%;
                min-height: 51px;
                text-align: center;
                margin-bottom: 20px;
                background: transparent;
                border: #014d9b solid 2px;
                color: #014d9b;
                font-size: 20px;
                font-weight: 600;
                box-shadow: none;
                @include respond-to(media-md) {
                    font-size: 18px;
                    margin-bottom: 15px;
                }
                @include respond-to(media-xs) {
                    min-height: 41px;
                }
                &:hover{
                    background: #0099ff;
                    color: #fff;
                    border: #0099ff solid 2px;
                }
            }
        }
        .heatMidBx {
            width: calc(100% - 540px);
            background: #fff;
            padding: 15px;
            border-radius: 6px;
            border: #e5e5e5 solid 1px;
            @include respond-to(media-md) {
                width: calc(100% - 400px);
            }
            @include respond-to(media-sm) {
                width: 100%;
                margin-bottom: 20px;
            }
            li{
                border: #014d9b solid 1px;
                padding: 10px;
                border-radius: 6px;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 20px;
                line-height: 30px;
                color: #014d9b;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .notifiRight{
                    position: relative;
                    input{
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        opacity: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 9;
                    }
                    input + span{
                        position: relative;
                        width: 60px;
                        border-radius: 15px;
                        height: 25px;
                        display: block;
                        background: #fff;
                        border: #014d9b solid 1px;
                        i{
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            left: 2px;
                            top: 1px;
                            background: #014d9b;
                            border-radius: 50%;
                            transition: all 0.5s ease-in-out;
                        }
                    }
                    input:checked + span{
                        background: #0099ff;
                        i{
                            left: auto;
                            right: 2px;
                            transition: all 0.5s ease-in-out;
                        }
                    }
                }
            }
        }
        .heatRightBx {
            width: 250px;
            padding: 15px;
            background: #fff;
            border-radius: 6px;
            border: #e5e5e5 solid 1px;
            @include respond-to(media-md) {
                width: 180px;
                padding: 10px;
            }
            @include respond-to(media-sm) {
                width: 100%;
            }
            button{
                width: 100%;
                min-height: 51px;
                text-align: center;
                margin-bottom: 20px;
                background: transparent;
                border: #014d9b solid 2px;
                color: #014d9b;
                font-size: 20px;
                font-weight: 600;
                @include respond-to(media-md) {
                    font-size: 18px;
                }
                @include respond-to(media-xs) {
                    min-height: 41px;
                }
                &:hover{
                    background: #0099ff;
                    color: #fff;
                    border: #0099ff solid 1px;
                }
            }
        }
    }
}
.positionOuter {
    margin: 0 270px;
    @include respond-to(media-md) {
        margin: 0 200px;
    }
    @include respond-to(media-sm) {
        margin: 0;
    }
    .heatMidBx {
        width: 100%;
        background: #fff;
        padding: 15px;
        border-radius: 6px;
        border: #e5e5e5 solid 1px;
        li{
            border: #014d9b solid 1px;
            padding: 10px;
            border-radius: 6px;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
            line-height: 30px;
            display: flex;
            color: #014d9b;
            justify-content: space-between;
            align-items: center;
            &::last-child{
                margin-bottom: 0;
            }
        }
    }
}
.modalOuterBx {
    max-width: 900px;
    @include respond-to(media-md) {
        padding: 0 10px;
    }
    
    .modalCloseBtn {
        background: transparent;
        color: #000;
        width: 38px;
        position: absolute;
        right: 0;
        font-weight: 600;
        z-index: 999;
    }
    .tabposition {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 40px;
        @include respond-to(media-sm) {
            display: none;
        }
        .tabpos_Left {
            width: 280px;
            font-size: 20px;
            font-weight: 600;
            color: #666;
        }
        .tabpos_Right{
            width: calc(100% - 310px);
            font-size: 20px;
            font-weight: 600;
            color: #666;
        }
    }
    .ModtabOuter{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 10px;
        @include respond-to(media-sm) {
            padding-top: 40px;
            display: block;
        }
        ul.nav.nav-tabs {
            width: 280px;
            display: block;
            border: 0;
            @include respond-to(media-sm) {
                width: 100%;
                padding-bottom: 10px;
            }
            li{
                display: block;
                padding-bottom: 10px;
                a{
                    border: #014d9b solid 2px;
                    padding: 10px;
                    font-size: 20px;
                    border-radius: 6px;
                    color: #014d9b;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: 500;
                    @include respond-to(media-sm) {
                        font-size: 18px;
                    }
                    span{
                        font-size: 16px;
                    }
                    &.active{
                        border: #0099ff solid 2px;
                        background: #0099ff;
                        color: #fff;
                    }
                }
            }
        }
        .tab-content {
            width: calc(100% - 310px);
            @include respond-to(media-sm) {
                width: 100%;
            }
            @include respond-to(media-xs) {
                padding: 10px;
            }
            .dnfOuter {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                border: #014d9b solid 1px;
                padding: 15px;
                border-radius: 6px;
                .dnfLeft {
                    width: 60%;
                    @include respond-to(media-xs) {
                        width: 60%;
                    }
                    li {
                        position: relative;
                        
                        label{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border: #014d9b solid 1px;
                            margin-bottom: 15px;
                            padding: 10px 15px;
                            font-size: 20px;
                            color: #666;
                            border-radius: 6px;
                            @include respond-to(media-sm) {
                                font-size: 18px;
                            }
                            @include respond-to(media-xs) {
                                font-size: 17px;
                            }
                            span{
                                &:last-child{
                                    color: #014d9b;
                                    font-weight: 600;
                                }
                            }
                        }
                        input[type="radio"]{
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            opacity: 0;
                            width: 100%;
                            height:100%;
                        }
                        input[type="radio"]:checked + label{
                            background: #0099ff;
                            color: #fff;
                            border: #0099ff solid 1px;
                            span{
                                color: #fff;
                            }
                        }
                    }
                }
                .dnfRight {
                    width: 35%;
                    @include respond-to(media-xs) {
                        width: 35%;
                    }
                    button {
                        display: block;
                        width: 100%;
                        margin-bottom: 10px;
                        height: 51px;
                        background: #fff;
                        font-size: 20px;
                        color: #014d9b;
                        font-weight: 600;
                        border-radius: 6px;
                        border: #014d9b solid 2px;
                        @include respond-to(media-xs) {
                            height: 46px;
                            font-size: 18px;
                        }
                        &:hover{
                            background: #0099ff;
                            color: #fff;
                            border: #0099ff solid 1px;
                        }
                    }
                }
            }
        }
    }
}
.nextPrevBtn {
    text-align: right;
    padding-top: 15px;
    .backBtn {
        margin-right: 10px;
        background: #666;
        font-size: 18px;
        width: 110px;
        border: 0;
        height: 41px;
        box-shadow: none;
    }
    .nextBtn{
        background:transparent;
        border: #014d9b solid 2px;
        font-size: 18px;
        width: 110px;
        height: 41px;
        color: #014d9b;
        box-shadow: none;
        font-weight: 600;
        &:hover{
            background: #0099ff;
            color: #fff;
        }
    }
}
.listcolor{
    background-color: #bebfbf;
    border-radius: 6px;
                       // border: #014d9b solid 1px;
}
.eventDropdown{
    width: 20%;
padding: 8px 20px;
border-radius: 6px;
font-size: 17px;
border: 1px solid #b0b0b0;
font-weight: 500;
background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjFCRjU3NTExNUYxQjExRUNBNUY0OUQ2Rjg2MzQ4RjlGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjFCRjU3NTEyNUYxQjExRUNBNUY0OUQ2Rjg2MzQ4RjlGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MUJGNTc1MEY1RjFCMTFFQ0E1RjQ5RDZGODYzNDhGOUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MUJGNTc1MTA1RjFCMTFFQ0E1RjQ5RDZGODYzNDhGOUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4644a7AAABbklEQVR42mKsqKjgvHPnTq+RkdFRHR2dpV++fGHg4eFh4OfnZ/j8+TMDGxsbw48fPyQWLVrUaWBgMItZXFx8ytq1azNOnTrlaWJickVYWPgmIyMjAwcHB8Pfv39BGgR7enpWbNmyJfDZs2deTHJycqeApv0AmsbZ3Ny84MGDB/7c3NwMTExMDKysrKIdHR0r9uzZ48wABBISEqcZ+vv7GQoLCxN4eXm/A8X+i4iIfJw/f77ruXPneNzd3beDxEDY0dFxA9BAXmYHBweQey9oa2vfv3Llis/79++5gc5zP3HihO/+/fsdQCYDNa62sbGJ//Xr1xdmFxcXBiCDAWjyZXl5+afXr1/3fPv2Ld/jx49lQIrt7e03A3HC9+/fP4OcyQQSBHkSGBIMkpKS85OSkjKAfvgNEndyctoANDkKqPjT//8glzEwgG0AcZiZmRl+/vzJICQkdEFaWvodkP5lZmYWDxT7DJIDqQHZABBgAITilywF74BVAAAAAElFTkSuQmCC) no-repeat right 10px center;
margin-left: 14px;
}
.heatTitle{
    width: 50%;
    padding: 8px 21px;
    border-radius: 6px;
    font-size: 17px;
    border: 2px solid #ccc4dd;
    font-weight: 502;
    margin-left: 25%;
    background-color: white;
    text-align: center;
    color: #32325b;
    margin-bottom: 30px;
    margin-top: 33px;
}
.hideparticipant{
    color: #7c8686;
}
.bypassBtn{
    position: fixed;
left: 74%;
top: 2%;
}

.selected-value {
    margin-top: 20px;
  }

  #my-table {
    max-width: 800px;
    margin: 0 auto;
  }
  
  #my-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  #my-table th, #my-table td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ccc;
  }
  .select-wrapper {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .caret {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    color: #999;
    font-size: 12px;
  }
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  table.tablescore {
    thead {
        tr {
            th {
                &:first-child {
                    width: 180px;
                }
                &:last-child {
                    text-align: center;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                &:first-child {
                    min-width: 100px;
                }
                &:last-child {
                    text-align: center;
                }
            }
        }
    }
  }
  .scoreinputbox {
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    width: 90px;
  
    input.form-control {
        border-radius: 0.25rem 0px 0px 0.25rem;
        flex: 0 0 52%;
        max-width: 52%;
        padding: 5px;
        font-size: 15px;
        text-align: center;
        
    }
    select {
        flex: 0 0 20%;
        max-width: 20%;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        padding: 0.375rem 0.75rem;
        border-radius: 0 0.25rem 0.25rem 0px;
        background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjFCRjU3NTExNUYxQjExRUNBNUY0OUQ2Rjg2MzQ4RjlGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjFCRjU3NTEyNUYxQjExRUNBNUY0OUQ2Rjg2MzQ4RjlGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MUJGNTc1MEY1RjFCMTFFQ0E1RjQ5RDZGODYzNDhGOUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MUJGNTc1MTA1RjFCMTFFQ0E1RjQ5RDZGODYzNDhGOUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4644a7AAABbklEQVR42mKsqKjgvHPnTq+RkdFRHR2dpV++fGHg4eFh4OfnZ/j8+TMDGxsbw48fPyQWLVrUaWBgMItZXFx8ytq1azNOnTrlaWJickVYWPgmIyMjAwcHB8Pfv39BGgR7enpWbNmyJfDZs2deTHJycqeApv0AmsbZ3Ny84MGDB/7c3NwMTExMDKysrKIdHR0r9uzZ48wABBISEqcZ+vv7GQoLCxN4eXm/A8X+i4iIfJw/f77ruXPneNzd3beDxEDY0dFxA9BAXmYHBweQey9oa2vfv3Llis/79++5gc5zP3HihO/+/fsdQCYDNa62sbGJ//Xr1xdmFxcXBiCDAWjyZXl5+afXr1/3fPv2Ld/jx49lQIrt7e03A3HC9+/fP4OcyQQSBHkSGBIMkpKS85OSkjKAfvgNEndyctoANDkKqPjT//8glzEwgG0AcZiZmRl+/vzJICQkdEFaWvodkP5lZmYWDxT7DJIDqQHZABBgAITilywF74BVAAAAAElFTkSuQmCC) no-repeat center center;
    }
}
.disabled {
    pointer-events: none;
    background-color: #f5f5f5;
}


.fastest-time-checkbox{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    input{
        height: 19px;
        width: 19px;
    }
}
.score-inputs-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.fast-time-input-container{
    width: 143px;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    .fast-time-input{
        border-radius: 0.25rem 0px 0px 0.25rem;
        flex: 0 0 52%;
        max-width: 52%;
        padding: 5px;
        font-size: 15px;
        text-align: center;
    }
}
