.loginHeader {background: url(https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/completed_event_bg.jpg); height: 100px; position: relative; display: block; padding: 0;
    .close {color: #fff; position: absolute; right: 10px; top: 10px; width: 36px; height: 36px; background: #03315e; border-radius: 100%; display: flex; align-items: center; justify-content: center;}
    h5 {width: 100%;}
}
.modal-open {overflow: hidden; padding: 0 !important;}
.popupLogo {text-align: center; padding: 55px 0 0 0;
    img {width: 120px;}
}
.loginInner {padding: 15px;}

.loginForm {max-width: 360px; margin: auto; padding: 50px 0 0 0;
    li {width: 100%; margin-bottom: 20px;}
}
// edit by uttkarsh
.LoginBtn {max-width: 360px; margin: auto; margin-top: 10px ;
    .formBtn {width: 100%; border: none;}
}

.regText {text-align: center; max-width: 360px; margin: auto; padding: 30px 0 10px 0;
    span {font-size: 17px; font-weight: 600; display: block; padding-bottom: 15px;}
    .regbtn {border: 2px solid #044e95; width: 100%; color: #044e95; font-size: 20px; text-transform: uppercase; padding: 8px; font-weight: 600; background: #fff;
        &:hover {background: #044e95; color: #fff;}
    }
}