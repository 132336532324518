.new-cd-heatsheet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  table {
    border: 1px solid black;
    border-collapse: collapse;
  }
  table {
    margin: 0 auto !important;
  }
  th,
  td {
    border-bottom: 1px solid black;
    padding: 0 3px;
    border-collapse: collapse;
  }

  .col-1 {
    width: 250px;
  }
  .col-2 {
    width: 250px;
  }
  .col-3 {
    width: 150px;
  }
  .table-1 {
    margin-bottom: 20px !important;
    border: none;
    background-color: gray;
    color: white;
    th,
    td {
      border: none;
      padding: 3px 3px;
    }
  }
  button {
    margin: 10px;
    display: inline-block;
    background: #0095fd;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    padding: 10px 35px;
  }
  button:hover {
    background: #000;
  }
}

.new-driver-heatsheet {
  .container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
  }

  h1 {
    font-size: 20px;
    margin-bottom: 5px;
    text-align: start;
  }

  h2 {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 20px;
    text-align: start;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  thead th {
    font-size: 16px;
    text-align: left;
  }

  tbody td {
    font-size: 14px;
    text-align: left;
    padding: 5px 0;
  }
  .col-1 {
    width: 100px;
  }
  .col-2 {
    width: 100px;
  }
  .col-3 {
    width: 300px;
  }
  button {
    margin: 10px;
    display: inline-block;
    background: #0095fd;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    padding: 10px 35px;
  }
  button:hover {
    background: #000;
  }
}
