.race-series {
  .accordion__button:before {
    display: none !important;
  }
  .accordion__button:after {
    display: none !important;
  }
  .race-series-races {
    width: 97%;
  }
  .accordion__panel {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .create-race-series {
    margin-right: 30px !important;
  }
  .series-button {
    color: #01458b;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    border: 3px solid #0095fd;
    white-space: nowrap;
    padding: 8px 25px;
    border-radius: 30px;
    margin-right: 20px;
    background-color: white;
  }
  .series-button:hover {
    color: black;
  }

  // Dec 9th css
  li.showRaces > div:not(.left.rght) {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .series-button {
    @media (max-width: 899px) {
      font-size: 15px;
      padding: 5px 20px;
      margin-right: 15px;
    }
  }
  .showRaces {
    .left.rght {
        @media (max-width: 899px) {
        padding: 0;
        margin-bottom: 15px;
      }
    }

  }

  .race-series-races {
     @media (max-width: 899px) {
      .left.rght {
        flex-wrap: wrap;
        width: 100%;
      }

     }
  }

  .uocomingRaces {
    .race-series-races {
      .det{
        @media (max-width: 899px) {
          padding: 10px 0;
        }
      }        
    }   
  }
}
