@import '../../mixin.scss';
@import 'react-accessible-accordion/dist/fancy-example.css';
.profileBox {padding: 25px;
    @include respond-to(media-md) {padding: 15px;}
    .img {
        img {border-radius: 100%; width: 135px; height: 135px;}
    }
    .det {font-size: 15px; line-height: 28px; padding-top: 15px;
        @include respond-to(media-md) {font-size: 14px;}
        span {display: block; font-weight: 600; font-size: 20px;
            @include respond-to(media-md) {font-size: 18px;}
        }
        strong {font-weight: 600;}
    }
}

.userInfo {margin:0 25px 20px 25px; background: #eeeeee; padding: 10px 0; border-radius: 4px; display: flex;
    @include respond-to(media-md) {margin:0 15px 20px 15px;}
    li {padding: 0 10px; text-align: center; font-size: 16px; border-right: 1px solid #c6c6c6; width: 100%; white-space: nowrap;
        @include respond-to(media-md) {font-size: 14px;}
        &:last-child {border-right: none;}
        span {display: block; font-weight: 600;}
    }
}

.resultTable {
    .accordion {border: none; border-radius: 0;}
    .accordion__item {margin-bottom: 10px;}
    .accordion__button {padding: 6px 40px 6px 15px; color: #000; background: #ecebeb; font-size: 16px; font-weight: 700; text-transform: uppercase; position: relative;
        @include respond-to(media-sm) {font-size: 14px; padding: 6px 30px 6px 10px;}
        &:before {display: none;}
        &:after {content:'+'; position: absolute; right: 10px; top: 4px; font-size: 22px; font-weight: 600;
            @include respond-to(media-sm) {top: 1px; right: 5px;}
        }
    }

    .accordion__button[aria-expanded='true']::after, .accordion__button[aria-selected='true']::after {content: '-';}
    .accordion__panel {padding: 10px;
        @include respond-to(media-sm) {padding: 5px;}
    }
}

.rrList {
    li {padding: 3px 5px; font-size: 13px; line-height: normal;
        .lft {font-weight: 500; padding-right: 10px;}
    }
}
.raceAttended {padding-bottom: 20px;
    .hd {background: #b2c9e1; font-size: 15px; font-weight: 700; padding: 6px 10px;}
    .raceAttendedList {padding: 10px;
        @include respond-to(media-md) {padding: 5px;}
        li {display: flex; padding: 2px 0; font-size: 15px;
            @include respond-to(media-md) {flex-wrap: wrap; padding: 4px 0;}
            .lft {width: 50%; padding-right: 10px; color: #014d9b; font-weight: 500;
                @include respond-to(media-md) {width: 100%; padding: 0;}
            }
            .rgt {width: 50%; text-align: right; font-weight: 400;
                @include respond-to(media-md) {width: 100%; text-align: left;}
            }
        }
    }
}