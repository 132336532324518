@media only screen and (max-width:1600px) {
    .dbLeft {width: 140px;}
    .dbMenu ul li {padding: 10px;}
    .myRace {width: 380px;}
    .userName {padding: 20px; font-size: 19px;}
    .raceListBox {padding: 0 20px;}
    .myRaceHd {font-size: 22px; }
    .raceList li {margin-top: 10px;}
    .raceList li a .date {font-size: 14px;}
    .raceList li a .name {font-size: 18px; line-height: 24px;}
    .dbRight {padding: 0 0 0 140px;}
    .dbMid {padding: 20px; margin-right: 380px;}

    .dbMainHd {font-size: 28px;}
    .dbTop {margin: -15px;}
    .dbTop li {padding: 15px;}
    .dbTop li .inner {padding: 20px; font-size: 17px;}
    .dbTop li .inner span {font-size: 42px;}
    .uocomingRaces li {padding: 15px;}
    .uocomingRaces li .icon {min-width: 60px;}
    .uocomingRaces li .icon img {border-radius: 100%; height: 60px; width: 60px;}
    .uocomingRaces li .det {padding: 0 15px; font-size: 13px;}
    .uocomingRaces li .det .hd {font-size: 18px;}
    .uocomingRaces li .rgt a {font-size: 16px; padding: 8px 20px;}
    .profileDet li .inner {padding: 20px;}
    .profileHd {font-size:25px;}
    .raceDetail {width: 380px; padding: 20px 20px 60px 20px;}
    a.viewMoreDet {width: 380px;}
    .raceMid {padding: 20px; margin-right: 380px;}
    .resultMidLeft {padding: 20px;}
    .resultMidRight {padding: 20px;}

    .myClubList li {padding: 15px;}
    .memebrBtnBox {width: 380px;}
    .memebrBtnBox a {font-size: 15px;}
    .dbWhtBox {padding:20px;}

}



@media only screen and (max-width:1200px) {
    .bannerCon .container {height: 420px;}
    .footerInfo {width: 260px;}
    .quickLinks {width: 280px;}
    .contactInfo {width: 300px;}

    .eventTab {padding: 20px 20px 0 20px;}
    .eventTab li {font-size: 19px;}
    .eventDetCon {padding: 0 20px 20px 20px;}
    .eventList li {font-size: 14px; margin-bottom: 20px;}
    .eventList li .top > div {padding: 6px 10px;}
    .eventList li .top > div.closed {font-size: 12px; padding: 10px 10px;}
    .eventList li .bot > div {width:30%; padding: 15px 10px;}
    .clubLft {padding: 0 20px 20px 20px;}
    .clubRgt {padding: 20px;}
    .clubHd {font-size: 18px;}
    .raceLft {padding: 0 20px 20px 20px;}

    .myRace {width: 100%; height: 70px; overflow: visible; box-shadow: 0 0 10px rgba(0,0,0,.1); justify-content: flex-end; display: flex;}
    .userName {position: relative; display: flex; align-items: center; width: auto; padding: 10px 155px 10px 10px; font-size: 16px; z-index: 102;}
    .showRace {display: block; white-space: nowrap; background: #525de0; font-size: 13px; color: #fff; font-weight: 500; padding: 7px 10px; border-radius: 4px; position: absolute; right: 10px;}
    .showRace .show {font-style: normal;}
    .showRace .hide {display: none; font-style: normal;}
    .showRace.active .show {display: none;}
    .showRace.active .hide {display: inline-block;}
    .dbMid {margin:70px 0 0 0;}
    #myRaces {position: fixed; height: calc(100% - 70px); width: 400px; background: #fff; overflow: auto; bottom: 0; right: -400px; transition: ease-in-out .4s; box-shadow: 0 0 10px rgba(0,0,0,.1); padding-top: 10px;}
    #myRaces.active {right: 0;}
    .profileDet {margin: -10px;}
    .profileDet > li {width:100%; padding: 10px;}

    .raceMid {margin-right: 0;}
    .raceDetail  {display: none;}
    .raceDetail.active {display: block; z-index: 103;}
    
    .hideRaces {display: block; width: 28px; height: 28px; position: absolute; right: 10px; top: 10px; z-index: 201; background: #666; color: #fff; border-radius: 4px; display: flex; justify-content: center; align-items: center;}

    .memebrBtnBox {width: 100%; max-width: 400px; right: -400px; transition: ease-in-out .4s;}
    .raceListBox.active  .memebrBtnBox {right: 0;}
    .membershipForm {margin: -10px;}
    .membershipForm li {padding: 10px;}
    .editForm  {margin: -10px;}
    .editForm li {padding: 10px;}
    /*.UEDet {width: calc(100% - 600px); }
    .UEImage {width: 600px; height: 270px;}*/
}

@media only screen and (max-width:999px) {    
    a.showMobMenu {display: block; width: 44px; height: 34px; background: #000; border-radius: 3px; padding-top: 9px; position: absolute; right: 15px; top: 18px;}
    a.showMobMenu i {width: 24px; height: 2px; background: #fff; display: block; margin: 0 auto 5px auto; transition:ease-in-out .3s;}
    a.showMobMenu.active {z-index: 10;}
    a.showMobMenu.active i:nth-child(3) {display: none;}
    a.showMobMenu.active i:nth-child(1) {transform: rotate(45deg); margin: 7px auto 0 auto;}
    a.showMobMenu.active i:nth-child(2) {transform: rotate(-45deg); margin:-1px auto 0 auto;}

    .headerInner {height: 100%; width: 280px; background: #fff; position: fixed; left: -280px; top: 0; overflow: auto; z-index: 100; display: block; transition: ease-in-out .5s; align-items: flex-start; box-shadow: 0 0 10px rgba(0,0,0,.2);}
    .headerInner.active {left: 0;}
    .menu {width: 100%;}
    .menu ul {display: block;}
    .menu ul li {margin-right: 0px; border-bottom: 1px solid #eee;}
    .menu ul li a {padding: 0 20px; height: 60px;}
    .headerRgt {flex-direction: column-reverse; width: 100%; padding: 20px 0 0 0;}
    .socialLinks {width: 100%; padding: 20px;}
    .socialLinks li {padding: 0 16px 0 0;}
    .topButtons {width: 100%;}
    .topButtons li {margin-left: 20px;}   
    .topButtons .linkInner {margin-left: 20px;}   
    .logo {left: 0; top: 0; margin-left: 0;}
    .logo img {width: 110px;}
    .header.sticky .logo img {transform: scale(.7); margin-top: -15px;}
    .bannerCon .container {height: 360px;}
    .bannerWht {font-size: 40px; line-height: 40px;}
    .bannerBlue {font-size: 28px;}
    .bannerText {padding: 10px 0 20px 0; color: #fff;}
   .UEDet {padding: 0 20px 60px 0; font-size: 16px; line-height: 26px;}
    .UEDet span {font-size: 28px; padding-bottom: 5px;}
    .UEImage .date {font-size: 17px; padding: 20px;}
    .UEImage .text {font-size: 22px; padding: 20px;}
    .UEImage .text span {font-size: 32px;}
    .mainHd {font-size: 28px; line-height: 28px;}
    .hdText {width: 380px;}
    .comEventBox {margin: -5px;}
    .comEventBox li {padding: 5px;}
    .comEventBox li .inner {height: 220px;}
    .comEventBox li .inner .lft {width: 130px;}
    .comEventHd {font-size: 28px; padding:15px; width: 200px; line-height: 28px;}
    .comEventBtn {padding:15px;}
    .comEventBtn a {font-size: 16px; width: 140px; height: 46px; line-height: 46px;}
    .comEventBox li .inner .rgt {width: calc(100% - 130px);}
    .newsSlider .slick-list {margin: 0 -5px;}
    .newsSlider .item {padding: 0 5px;}
    .clientList {margin: -5px;}
    .clientList li {padding: 5px;}
    .footerHd {font-size:18px; padding-bottom: 10px;}
    .footerInfo {width: 25%;}
    .footerText {font-size: 15px;}
    .quickLinks {width: 22%;}
    .footerLinks {width: 100%;}
    .footerLinks li {font-size: 15px;}
    .contactInfo {width: 32%;}
    .contactInfo .info {font-size: 15px;}
    .contactInfo .info.location .icon {top: 0px;}
    .contactInfo .info.phone .icon {top: 1px;}
    .contactInfo .info.mail .icon {top: -3px;}
    .footerSM li {padding: 0 10px 0 0;}
    .innerBanner .container {height: 220px;}
    .innerHd {font-size: 32px;}

    .dbTop {margin: -5px;}
    .dbTop li {padding: 5px;}
    .dbTop li .inner {padding: 20px 15px; font-size: 15px;}
    .dbTop li .inner span {font-size: 30px;}
    .dbTop li .inner .icon {position: absolute; right: 10px; top: 50%; margin-top: -20px; width: 40px;}
    .uocomingRaces li {flex-wrap: wrap;}
    .uocomingRaces li .left {width: 100%;}
    .uocomingRaces li .rgt {width: 100%; justify-content: flex-end; padding: 10px 0 0 0;}

    .resultMidLeft {width: 100%;}
    .resultList li a {padding:10px 60px 10px 15px;}
    .resultList li a .name {font-size: 18px; line-height: 24px;}
    .resultList li a .date {font-size: 14px;}

    .resultMidRight {display: none; z-index: 200; position: fixed; left: 0; top: 0; width: 100%; height: 100%;}
    .resultMidRight.active {display: block;}
    .hideResult {display: block; width: 28px; height: 28px; position: absolute; right: 10px; top: 10px; z-index: 201; background: #666; color: #fff; border-radius: 4px; display: flex; justify-content: center; align-items: center;}
    .rsultTable table {font-size: 13px;}
    
    .myClubList li {flex-wrap: wrap;}
    .myClubList li .rgt {width: 100%; justify-content: space-between; padding-top: 10px;}

    .topHdBox .left {width: 100%; padding-right:0;}
    .topHdBox .left .text {font-size: 16px;}
    .topHdBox .right {width: 100%;}
    .topHdBox .right .inner {text-align: left; font-size: 16px;}
    .dbSubHd {font-size: 20px;}
    .labelText {font-size: 16px; margin-right: 15px;}
    .formInput {padding: 10px 15px; font-size: 16px;}
    .formSelect {padding: 10px 15px; font-size: 16px;}
    .editForm li {width: 50%;}
    .profileInfo {font-size: 13px;}
    .profileInfo .hd .icon {font-size: 18px; width: 18px; height: 18px;}

    .dbTable table th {font-size: 13px;}
    .dbTable table td .hd {font-size: 15px;}
    .dbTable table td .hd span {font-size: 12px;}
    .summarylist li {font-size: 13px; display: block;}
    .summarylist li span {display: block; width: 100%; min-width: 100%;}
    .dbTable table td .status {font-size: 14px; width: 90px;}
    .reqLink {width: 130px;}
}

@media only screen and (max-width:767px) {
    .bannerCon {position: relative;}
    .bannerCon:before {content: ''; width: 100%; height: 100%; position: absolute; left: 0; top: 0; background: rgba(0,0,0,.6);}
    .bannerInfo {position: relative; z-index: 10;}
    .bannerCon .container {height: 300px;}
    .bannerWht {font-size:36px; line-height: 36px;}
    .bannerBlue {font-size: 24px;}
    .upcomingBox {flex-direction: column-reverse;}
    .UEDet {width: 100%; height: auto; margin: 0; padding: 15px 0 60px 0;}
    .UEDet:before {display: none;}
    .UEImage {width: 100%; height: 250px;}
    .UEImage .date {padding: 15px;}
    .UEImage .text {font-size: 19px; padding: 15px;}
    .UEImage .text span {font-size: 28px;}
    .upcomingSlider .slick-dots {bottom: 20px; left: 15px;}
    .upcomingSlider .slick-arrow {top: 15px;}
    .pt50 {padding-top: 30px;}
    .pb50 {padding-bottom: 30px;}
    .mainHd {text-align: left; font-size: 26px; line-height: 26px;}
    .mainHd span {display: inline-block;}
    .hdText {width: 100%; max-width: inherit; border-left: none; border-top: 1px solid #000; margin: 10px 0 0 0; padding: 10px 0 0 0;}
    .comEventBox li {width: 100%;}
    .newsSlider {margin-top: 50px;}
    .newsSlider .item .over {opacity: 1; padding: 15px;}
    .newsSlider .item .over .hd {font-size: 20px; line-height: 22px;}
    .newsSlider .item .over .hd span {font-size: 15px;}
    .clientList li {width: 50%;}
    .footerInfo {width: 100%; padding: 20px 10px;}
    .quickLinks {width: 100%; padding: 0 10px 20px 10px;}
    .footerLinks {display: flex;}
    .footerLinks li {width: 33.33%;}
    .contactInfo {width: 100%; padding: 0 10px 20px 10px;}
    .contactInfo .info {margin-bottom: 15px;}
    .socialMedia {width: 100%; padding: 0 10px 20px 10px;}
    .innerBanner .container {height: 180px;}
    .innerHd {font-size: 28px;}
    .clubLft {padding: 0 10px 10px 10px; width: 100%; border-bottom: 1px solid #cecfcf; border-right: none;}
    .clubList li a {padding: 10px; font-size: 14px;}
    .clubRgt {padding: 10px; width: 100%;}

    .raceLft {width: 100%; padding: 0 10px 10px 10px; border-right: none; border-bottom: 1px solid #cecfcf;}
    .raceRgt {width: 100%;}
    // .raceRgt2 {width: 100%;}
    .rrTop {padding: 10px 10px 0 10px;}
    .rrTop .hd {font-size: 16px;}
    .rrTop .hd span {font-size: 13px;}
    .rrTbalehd {padding: 10px 10px 5px 10px;}
    .rrTable table th {padding: 6px 10px;}
    .rrTable table td {padding: 6px 10px;}

    .mb30 {margin-bottom: 20px;}
    a.showDBMenu {display: block; width: 100%; height: 70px; background: #fff; position: fixed; left: 0; top: 0; z-index: 100; padding: 18px 15px; box-shadow:0 0 10px rgba(0,0,0,.2);}

    a.showDBMenu span {display: block; width: 44px; height: 34px; background: #000; border-radius: 3px; padding-top: 9px;}
    a.showDBMenu i {width: 24px; height: 2px; background: #fff; display: block; margin: 0 auto 5px auto; transition:ease-in-out .3s;}
    a.showDBMenu.active {z-index: 10;}
    a.showDBMenu.active i:nth-child(3) {display: none;}
    a.showDBMenu.active i:nth-child(1) {transform: rotate(45deg); margin: 7px auto 0 auto;}
    a.showDBMenu.active i:nth-child(2) {transform: rotate(-45deg); margin:-1px auto 0 auto;}

    .dbLeft {height: calc(100% - 70px); top: 70px; left: -120px; transition: ease-in-out .4s;}
    .dbLeft.active {left: 0;}
    .dbLogo img {width: 80px;}
    .dbMenu ul li {padding: 10px;}
    .dbMenu ul li a .icon {width: 40px; height: 40px;}
    .dbMenu ul li a .icon span {font-size: 24px; width: 24px; height: 24px;}
    .userName {padding: 10px 125px 10px 10px; font-size: 13px; background: none;}
    .userName img {width: 40px;}
    .showRace {font-size: 11px;}
    .dbRight {padding:0;}
    .dbMid {padding: 15px;}
    .dbTop li {width: 100%;}
    .dbMainHd {font-size: 22px;}
    .uocomingRaces li {padding: 10px;}
    .uocomingRaces li .det {padding: 0 0 0 10px; font-size: 12px;}
    .uocomingRaces li .det .hd {font-size: 15px;}
    .uocomingRaces li .rgt a {font-size: 16px; border-width: 2px; padding: 7px 20px;}
    .myRace {width:calc(100% - 70px); z-index: 102; box-shadow: none;}
    #myRaces {max-width: 400px; width: 100%;}
    .raceList li a {padding: 15px;}
    .raceList li a .name {font-size: 16px; line-height: 22px;}
    .dbMid.dbInner {margin: 70px 0 0 0 !important;}
    .profileDet li .inner {padding: 15px;}
    .profileHd {font-size: 20px;}
    .raceAttended li .left {font-size: 18px;}
    .raceMid {margin-top: 70px;}
    
    .resultList li a .name {font-size: 16px; line-height: 22px;}
    .resultList li a .date {font-size: 13px;}
    .resultMidRight {padding: 10px;}
    .resultHd {font-size: 20px;}
    .resultTop .hd {font-size: 17px; line-height: 21px;}
    .rsultTbalehd {font-size: 15px;}

    .raceDetail  {width: 100%;}
    a.viewMoreDet {width: 100%;}

    .dbAdminLeft {height: calc(100% - 70px); top: 70px; left: -200px; transition: ease-in-out .4s;}
    .dbAdminLeft.active {left: 0;}
    .dbAdminRight {padding: 0;}

    .upcomingSlider {width: 100%; position: relative; margin-bottom: 15px;}
}

@media only screen and (max-width:599px) {
    .eventTab {padding: 20px 10px 0 10px;}
    .eventTab li {margin-right: 15px; font-size: 15px;}
    .eventTab li.active a:after {height:3px;}
    .eventList li .top {padding:5px 0;}
    .eventList li .top > div {width: 100%; padding: 2px 10px;}
    .eventList li .top > div:first-child {width: 100%;}
    .eventList li .top > div.closed {padding: 2px 10px; text-align: left;}
    .eventList li .bot {padding-top: 5px;}
    .eventList li .bot > div {width:100%; padding:2px 10px; text-align: left;}
    .eventList li .bot > div:first-child {width: 100%;}
    .searchBox {width: 100%; margin-bottom: 15px;}
    .topLink {margin-bottom: 15px; width: 100%; justify-content: flex-end;}
    .userInfo .right {width: 100%; padding: 15px 0 0 0;}
    .raceAttended li {flex-wrap: wrap;}
    .raceAttended li .left {width: 100%; font-size: 18px;}
    .raceAttended li .right {text-align: left;}
    .userInfo .right a.editBtn {top: 25px; font-size: 15px; height: 36px; line-height: 34px; width: 60px;}
    .myClubList li .left a img {min-width: 60px; width: 60px;}
    .myClubList li .left a {font-size: 15px;}
    .myClubList li .rgt {flex-wrap: wrap;}
    .myClubList li .rgt .district {margin: 0 0 5px 0;}
    .myClubList li .rgt .name {text-align: right; margin:0 0 5px 0; width: 50%;}
    .myRaceHd {font-size:18px;}
    .memberList li a {padding: 15px;}
    .memberList li a .name {font-size: 15px;}
    .membershipForm {margin: -5px; padding-bottom: 15px;}
    .membershipForm li {width: 100%; padding: 5px;}
    .dbWhtBox {padding: 15px;}
    .labelText {display: block; width: 100%; margin-bottom: 5px;}
    .editForm  {margin: -5px; padding-bottom: 15px;}
    .editForm li {width: 100%; padding: 5px;}
}

    