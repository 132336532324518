.masterTable {
  width: 100%;
  overflow: auto;
  font-family: "Poppins", sans-serif;
  padding: 0px;

  table {
    width: 100%;
    border-spacing: 0px;
    border-collapse: initial;

    th {
      background: #d2d0d0;
      padding: 5px 4px;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
    }

    td {
      background: #fff;
      padding: 5px 4px;
      font-size: 10px;

      &.gray {
        background: #fff;
        font-weight: 700;
        text-align: center;
      }

      &.grayTotal {
        background: #e8e7e7;
        font-weight: 700;
        padding: 5px 7px;
      }

      &.hd {
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        padding: 5px 7px;
      }

      table {
        background: #d2d0d0;
        border-spacing: 2px;
      }
    }
  }
}

.resultTable {
  width: 100%;
  overflow: auto;
  font-family: "Poppins", sans-serif;
  padding: 0px;

  table {width: 100%; border-spacing: 0px; border-collapse: initial;
    tr {
      th {border-bottom: 2px solid #d6d6d6; padding: 8px 10px; font-weight: 600;}
    }
    tr {
      td {background: #e5f4fe; padding: 15px 10px; border-top: 2px solid #fff;}
      &:nth-child(even) {
        td {background: #fef2f2;}
      }
    }
  }
}

