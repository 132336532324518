@import "../../../mixin.scss";

.formSelect.small {
  padding: 8px 35px 10px 15px;
  border-radius: 40px;
  font-size: 15px;
  border: 2px solid #212b35;
}
.productHd {
  font-size: 16px;
  text-transform: uppercase;
  @include respond-to(media-md) {
    font-size: 14px;
  }
  span {
    font-size: 22px;
    font-weight: 700;
    @include respond-to(media-md) {
      font-size: 20px;
    }
  }
}
.productList {
  margin: -15px;

  @include respond-to(media-md) {
    margin: -10px;
  }

  li {
    padding: 15px;

    @include respond-to(media-md) {
      padding: 10px;
    }

    .img {
      width: 100%; // Make the container responsive
      padding-top: 100%; // Maintain a 1:1 aspect ratio
      position: relative; // Required for absolute positioning of the image
      overflow: hidden; // Ensure the image doesn't overflow outside the container
      border: 1px solid #ddd; // Optional: Add a border for better visual structure

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain; // Maintain the aspect ratio while filling the container
      }
    }

    .name {
      font-size: 18px;

      @include respond-to(media-md) {
        font-size: 16px;
      }

      span {
        font-size: 21px;
        font-weight: 700;

        @include respond-to(media-md) {
          font-size: 18px;
        }
      }
    }
  }
}

.detailImg {
  img {
    width: 100%;
  }
}
.detailInfo {
  .name {
    font-size: 17px;
    span {
      font-size: 21px;
      font-weight: 700;
    }
  }
  .price {
    font-size: 27px;
    font-weight: 700;
  }
  .quantity {
    font-size: 18px;
    font-weight: 700;
    input {
      width: 80px;
      border: 1px solid #4a4a4a70;
      padding: 10px;
    }
  }
  .addCart {
    button {
      width: 100%;
      background: #0095fd;
      color: #fff;
      font-size: 17px;
      font-weight: 700;
      padding: 12px;
      &:hover {
        background: #01458b;
      }
    }
  }
  .saveForLater {
    span {
      font-size: 15px;
      font-weight: 700;
    }
    a {
      display: inline-flex;
      border: 1px solid #b7b7b7;
      padding: 12px 30px;
      font-size: 15px;
      font-weight: 600;
      align-items: center;
      span {
        font-size: 24px;
        margin-right: 10px;
        font-weight: 400;
      }
    }
  }
  .share {
    span {
      font-size: 15px;
      font-weight: 700;
    }
  }
}

.desText {
  color: #000000;
  font-family: 15px;
  line-height: 24px;
  ul {
    margin: 0;
    padding: 10px 0 0 0;
    li {
      position: relative;
      padding: 3px 0 3px 12px;
      &:before {
        content: "-";
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
  }
}

.cartInfo {
  .inner {
    border: 1px solid #b1b1b1;
    .img {
      width: 180px;
      img {
        width: 100%;
      }
    }
    .det {
      width: calc(100% - 180px);
      @include respond-to(media-xs) {
        width: 100%;
      }
      .name {
        font-size: 16px;
        font-weight: 700;
      }
      .price {
        font-size: 19px;
        font-weight: 700;
        .oldPrice {
          font-size: 14px;
          text-decoration: line-through;
        }
      }
      .links {
        a {
          color: #212b35;
          font-size: 14px;
          font-weight: 600;
          margin-right: 15px;
          @include respond-to(media-xs) {
            margin-right: 10px;
          }
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            color: #0095fd;
          }
        }
      }
    }
    .subHd {
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.payOption {
  .hd {
    font-size: 15px;
    font-weight: 700;
  }
  .payDetail {
    li {
      font-size: 15px;
      font-weight: 700;
      &.bor {
        border-top: 2px solid #cccccc;
      }
    }
  }
}
a.checkoutBtn {
  display: block;
  width: 100%;
  background: #0095fd;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  padding: 14px 12px;
  text-align: center;
  &:hover {
    background: #01458b;
  }
}
.notetext {
  font-size: 14px;
  line-height: 24px;
  color: #212b35;
}

.chekcoutForm {
  margin: -10px -15px;
  li {
    padding: 10px 15px;
    .filed {
      font-weight: 500;
    }
    .formInput {
      padding: 8px 15px;
    }
    .formSelect {
      padding: 8px 30px 8px 15px;
    }
  }
}

.borderBox {
  border: 1px solid #b1b1b1;
}

.myCart {
  .name {
    font-size: 16px;
    span {
      font-size: 19px;
      font-weight: 700;
    }
  }
  .price {
    font-size: 22px;
    font-weight: 700;
  }
  .quantity {
    font-size: 18px;
    font-weight: 700;
    input {
      width: 80px;
      border: 1px solid #4a4a4a70;
      padding: 10px;
    }
  }
}

.attribute-dropdown {
  font-size: 15px;
  font-weight: 600;
}

// my css

.carousel {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust as needed */
  overflow: hidden;
}

.carousel img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 30px;
  cursor: pointer;
  z-index: 10;
}

.carousel-btn.prev {
  left: 10px;
}

.carousel-btn.next {
  right: 10px;
}

/* Centered image index */
.image-index {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  color: white;
  z-index: 15; /* Ensure it stays above the image */
}
.product-name-out-of-stock {
  color: red;
  opacity: 0.4;
}

.emptyCart {
  text-align: center;
  padding: 50px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
  margin-bottom: 100px;

  h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333;
  }

  p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 20px;
  }

  .goToProductPageBtn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;

    &:hover {
      background-color: #0056b3;
    }
  }
}

.cartActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quantityControl {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantityBtn {
  padding: 5px 10px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

.quantityDisplay {
  font-size: 16px;
  font-weight: 500;
}

.removeLink {
  font-size: 14px;
  color: #ff3b3b;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}


// detals page image modal css 

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  height: 90%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
}


.modal-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.image-navigation {
  position: absolute; /* Make it float above other elements */
  top: 50%; /* Move to the middle of the modal */
  transform: translateY(-50%); /* Center it vertically */
  display: flex;
  justify-content: space-between;
  width: 100%; /* Ensure it spans the modal width */
  padding: 0 20px; /* Optional: add some padding for the navigation buttons */
  z-index: 10; /* Ensure it is above other elements */
}


.prev-button,
.next-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #000;
}



/* Loader overlay */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;  /* Ensure it's on top of other elements */
  pointer-events: none;  /* Allow interaction with the background */
}

/* Circular spinner */
.loadingSpinner {
  border: 4px solid #f3f3f3;  /* Light gray border */
  border-top: 4px solid #3498db;  /* Blue color for the top part */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;  /* Spin animation */
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.filBtnWrp {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  .sortBtn {
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #000000;
    background: transparent;
    border: none;
    margin-right: 35px;
    img {
      margin-right: 8px;
    }
  }
}

.nambaBtn {
  margin-bottom: 10px;
  margin-left: 100px;
  font-size: 18px;
  font-weight: 600;
  line-height: 42px;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
  padding: 10px;
  display: inline-block;
  border-radius: 4px;
  background: #01458b;
  border: 1px solid transparent;
  transition: all 0.5s ease-in;

  &:hover {
    color: #01458b;
    background: #fff;
    border-color: #01458b;
  }
}


.newDbRight.myOrderCon {padding: 50px 0 0 0;}

.status-badge {
  display: inline-block;
  padding: 0px 20px;
  border-radius: 15px;
  color: white;
  font-size: 0.85rem;
  text-align: center;
}

.status-completed-delivered {
  background-color: #28a745;
}

.status-pending {
  background-color: #ffc107;
}

.status-failed-cancelled {
  background-color: #dc3545;
}

.status-default {
  background-color: #adb5bd;
}

