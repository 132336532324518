@import '../../mixin.scss';
.addEventTop {padding: 25px; border-bottom: 2px solid #e3e3e3; font-family: 'Poppins', sans-serif;
    @include respond-to(media-lg) {padding: 15px;}
    @include respond-to(media-sm) {padding: 10px;}
    .left {width: 60%; font-size: 18px; font-weight: 600; padding-right: 20px;
        @include respond-to(media-md) {font-size: 16px;}
        @include respond-to(media-xs) {width: 100%; padding: 0 0 10px 0;}
        img {min-width: 70px; height: 70px; margin-right: 15px;}
    }
    .right {max-width: 40%; font-size: 15px; font-weight: 600; color: #333;
        @include respond-to(media-md) {font-size: 14px;}
        @include respond-to(media-xs) {width: 100%; max-width: 100%;}
    }
}
.specialNote{
    padding:5px
}
.entriesButtonEdit{

    color: #0d6efd;
    
}

.entriesButtonDele{

    color: #0d6efd;
    
}

.addEventMid {padding: 25px; border-bottom: 2px solid #e3e3e3; font-family: 'Poppins', sans-serif;
    @include respond-to(media-lg) {padding: 15px;}
    @include respond-to(media-sm) {padding: 10px;}
}
.addEventTable {width: 100%; overflow: auto;
    table {width: 100%;
        th {background: #ecebeb; font-size: 16px; text-transform: uppercase; font-weight: 700; text-align: center; padding: 5px 10px;
            @include respond-to(media-sm) {font-size: 14px; padding: 5px;}
            &:first-child {text-align: left;}
        }
        td {padding: 5px 10px; font-size: 13px; font-weight: 500; text-align: center; white-space: nowrap;
            &:first-child {text-align: left;} 
            @include respond-to(media-sm) {padding: 5px;}
            strong {font-weight: 700;}
        }
        .total {
            td {border-top: 2px solid #e3e3e3; border-bottom: 2px solid #e3e3e3;}
        }
        .totalInput {width: 80px; border: 1px solid #b0b0b0; border-radius: 6px; padding: 5px; text-align: center;}
    }
}

.addEventBot {padding: 25px; font-family: 'Poppins', sans-serif;
    @include respond-to(media-lg) {padding: 15px;}
    @include respond-to(media-sm) {padding: 10px;}
}
.paymentMetHd{background: #ecebeb; font-size: 16px; text-transform: uppercase; font-weight: 700; padding: 5px 10px;
    @include respond-to(media-xs) {font-size: 14px;}
}
.paymentType {text-align: right; padding: 10px 0; font-size: 13px; font-weight: 500;
    &.pad0 {padding: 0;}
    @include respond-to(media-xs) {text-align: left;}
    select {width: 120px; padding: 5px; font-weight: 600; margin: 0 10px;
        @include respond-to(media-xs) {margin: 0 10px 0 0;}
    }    
    span {
        @include respond-to(media-xs) {display: block; padding-bottom: 5px;}
    }
}
.continueBtn {background: #0095fd; border-radius: 6px; font-weight: 600; color: #fff; padding: 6px 15px; font-size: 17px;
    &:hover {background: #000;}
}

.eventInner {padding: 10px 10px 0 10px; font-size: 13px; font-weight: 500;
    @include respond-to(media-lg) {padding: 10px 0 0 0;}
}
.pb20 {padding-bottom: 20px;}
.eventForm {margin: -7px;
    li {width: 50%; padding: 7px; display: flex; flex-wrap: wrap; align-items: center;
        @include respond-to(media-lg) {display: block;}
        @include respond-to(media-xs) {width: 100%;}
        .lft {width: 180px; text-align: right;
            @include respond-to(media-lg) {width: 100%; text-align: left;}
            span {font-size: 10px; display: block;
                @include respond-to(media-lg) {display: inline-block;}
            }
        }
        .rgt {width: calc(100% - 180px); padding-left: 10px;
            @include respond-to(media-lg) {width: 100%; padding: 5px 0 0 0;}
            .infoIcon {margin-left: 10px;}
        }
        .formInput, .formSelect {padding: 8px 15px;
            @include respond-to(media-sm) {padding: 8px 10px;}
        }
    }
}

.userInfoList {padding: 10px;
    li {font-size: 13px; font-weight: 500; width: 100%; display: flex; flex-wrap: wrap; padding: 5px 0;
        .left {width: 60%;}
        .right {width: 40%; text-align: right; padding-left: 10px;}
    }
}

.regInfo {width: 58%;
    @include respond-to(media-sm) {width: 100%;}
}
.classApp {width: 40%; border-left: 1px solid #ccc; padding-left: 20px;
    @include respond-to(media-sm) {width: 100%; padding: 20px 0 0 0; margin-top: 20px; border-left: none; border-top: 1px solid #ccc;}
}

.cartList {margin: -10px; padding-top: 20px;
    li {width: 33.33%; padding: 10px;
        @include respond-to(media-md) {width: 50%;}
        @include respond-to(media-xs) {width: 100%;}
        .inner {background: #fff; position: relative; border-radius: 4px; border: 1px solid #ccc;
            .hd {background: #ccc; padding: 12px 70px 12px 15px; font-size: 18px; font-weight: 500;}
            .close {position: absolute; right: 12px; top: 15px; cursor: pointer;}
            .acctable {padding: 15px;}
            .total {border-top: 1px solid #ccc; padding:10px 15px; display: flex; justify-content: space-between; font-size: 20px; font-weight: 500;}
        }
    }
}